import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/_Shared/FilterWrapper"

import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useFuelTypeQuery } from "@/hook/Dictionaries/useFuelTypeQuery"
import { useFilterStorage } from "@/hook/useFilterStorage"

import { FUEL_QUOTES_ARCHIVE_KEY } from "@/types/IFuelQuote"
import { FilterField, FilterFieldType } from "@/types/ui"

export const useFuelArchiveFilters = () => {
  const [filters, setFilters] = useState<FilterField[]>([])

  const { getFilter } = useFilterStorage({
    key: FUEL_QUOTES_ARCHIVE_KEY
  })

  const oldFilters: FieldData[] = getFilter()

  const { data: depots } = useDepotSelectListQuery()
  const { data: fuelTypes } = useFuelTypeQuery()

  useEffect(() => {
    setFilters([
      {
        text: "Нефтебаза",
        name: "depotId",
        type: FilterFieldType.Select,
        values: depots?.response.map((f) => ({
          id: f.id,
          name: f.name
        })) ?? []
      },
      {
        text: "Тип топлива",
        name: "fuelTypeId",
        type: FilterFieldType.Select,
        values: fuelTypes?.response.data.map((f) => ({
          id: f.id,
          name: f.name
        })) ?? []
      },
      ...FuelArchiveFilters
    ])
  }, [depots, fuelTypes])

  return {
    filters: filters.map((f) => {
      const currentFilter = oldFilters.find(
        (i) => (i.name as string[])[0] === f.name
      )

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      switch (f.type) {
        case FilterFieldType.Date:
          return {
            ...f, value: dayjs(new Date(currentFilter.value as string))
          }
        case FilterFieldType.DateRange:
          return {
            ...f, value: [
              dayjs(new Date(currentFilter.value[0] as string)),
              dayjs(new Date(currentFilter.value[1] as string))
            ]
          }
        default:
          return {
            ...f, value: currentFilter.value
          }
      }
    })
  }
}

const FuelArchiveFilters: FilterField[] = [
  {
    text: "Дата",
    name: "date",
    type: FilterFieldType.DateRange
  },
  {
    text: "Плотность",
    name: "density",
    type: FilterFieldType.Number
  },
  {
    text: "Цена реализации",
    name: "sellingPrice",
    type: FilterFieldType.Number
  },
  {
    text: "Цена закупки",
    name: "purchasePrice",
    type: FilterFieldType.Number
  },
  {
    text: "Дневной лимит",
    name: "dayLimit",
    type: FilterFieldType.Number
  },
  {
    text: "В заказе",
    name: "quantityOrdered",
    type: FilterFieldType.Number
  }
]
