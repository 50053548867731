import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ICustomerClient, ICustomerClientCreate, ICustomerClientUpdate } from "@/types/ICustomerClient"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ISelectListItem, ITableColumn } from "@/types/ui"

const controllerName = "CustomerClients"

export const getCustomerClients = (params: IRequestParams): Promise<IPagingResponse<ICustomerClient>> =>
  client.get(`/${controllerName}`, {
    params
  })

export const getCustomerClientsSelectList = (): Promise<IResponse<ISelectListItem[]>> => client.get(`/${controllerName}/selectList`)

export const getCustomerClientById = (id: number): Promise<IResponse<ICustomerClient>> => client.get(`/${controllerName}/${id}`)
export const getCustomerClientExcel = (params: IRequestParams, columns?: ITableColumn<ICustomerClient>[]): Promise<IResponse<IDownloadDocument>> => client.post(
  `/${controllerName}/Excel`, columns, { params }
  )

export const createCustomerClient = (body: ICustomerClientCreate): Promise<IResponse<number>> => client.post(`/${controllerName}`, body)

export const updateCustomerClient = (body: ICustomerClientUpdate): Promise<IResponse<string>> => client.put(`/${controllerName}`, body)

export const deleteCustomerClients = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete(`/${controllerName}`, {
    data: {
      ids
    }
  })
