import { useQuery } from "@tanstack/react-query"

import { TIME_ALL_UNLOAD_FUEL_BY_DATE_KEY, TIME_UNLOAD_FUEL_AVAILABLE_KEY } from "@/constants/query"
import { getAllTimeUnloadFuelByDate, getAvailableTimeUnloadFuel } from "@/http/timeUnloadFuel"

export const useTimeUnloadFuelQuery = (date: Date) => {
  return useQuery({
    queryFn: () => getAvailableTimeUnloadFuel({ date: date }),
    queryKey: [TIME_UNLOAD_FUEL_AVAILABLE_KEY]
  })
}


export const useAllTimeUnloadFuelByDateQuery = (date: Date) => {
  return useQuery({
    queryFn: () => getAllTimeUnloadFuelByDate({ date: date }),
    queryKey: [TIME_ALL_UNLOAD_FUEL_BY_DATE_KEY]
  })
}
