export interface IFuelQuote {
  id: number
  date: Date | string
  //purchasePrice: number
  sellingPrice: number
  //quantityOrdered: number
  //dayLimit: number
  commentary?: string
  density: number
  fuelId: number
}

export interface IFuelQuoteCreate {
  date: Date | string
  //purchasePrice: number
  sellingPrice: number
  //dayLimit: number
  commentary?: string
  density: number
  fuelId: number
}

export interface IFuelQuoteUpdate extends IFuelQuoteCreate {
  id: number
}

export interface IFuelQuoteArchive {
  id: number
  date: Date
  purchasePrice: number
  sellingPrice: number
  quantityOrdered: number
  dayLimit: number
  commentary?: string
  density: number
  fuelId: number
  depotId: number
}

export interface IFuelQuoteCard {
  id: number
  date: Date | string
  sellingPrice: number
  commentary?: string
  density: number
  fuelId: number
  fuelQuoteCategorySettings: IFuelQuoteCategorySetting[]
}

export interface IFuelQuoteCategorySetting {
  id: number
  addonPrice: number
  showAddonPrice: number
  categoryId: number
  categoryName: string
  fuelId: number
  fuelCategorySettingQuote: IFuelQuoteCategorySettingQuote
}

export interface IFuelQuoteCategorySettingQuote {
  id: number
  date: Date 
  addonPrice: number 
  fuelCategorySettingId: number
}

export interface IFuelQuoteCategorySettingRequest {
  addonPrice: number
  date: Date | string
	fuelCategorySettingQuoteId?: number
	fuelId: number
  categoryId: number
  categorySettingId: number
}

export const FUEL_QUOTES_KEY = "FUEL_QUOTES_KEY"
export const FUEL_QUOTES_ARCHIVE_KEY = "FUEL_QUOTES_ARCHIVE_KEY"
