import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { IDispatcherOrder, IOrderChangeStatus, IOrderCoordinateTime } from "@/types/IOrder"
import { ITableColumn } from "@/types/ui"

export const getDispatcherOrderById = (id: number): Promise<IResponse<IDispatcherOrder>> =>
  client.get(`/DispatcherOrders/${id}`)

export const getDispatcherOrder = (
  params: IRequestParams
): Promise<IPagingResponse<IDispatcherOrder>> =>
  client.get("/DispatcherOrders", {
    params
  })

export const getDispatcherTimeCoordinate = (
  params: IRequestParams
): Promise<IPagingResponse<IDispatcherOrder>> =>
  client.get("/DispatcherOrders/time-coordinate", {
    params
  })

export const getDispatcherTimeCoordinateCount = () : Promise<IResponse<number>> => 
  client.get("/DispatcherOrders/time-coordinate/count")
  
export const getDispatcherOrdersExcel = (params: IRequestParams, columns?: ITableColumn<IDispatcherOrder>[]):
  Promise<IResponse<IDownloadDocument>> => client.post("/DispatcherOrders/Excel",
  columns, {
  params
})

export const getDispatcherTimeCoordinateExcel = (params: IRequestParams, columns?: ITableColumn<IDispatcherOrder>[]):
  Promise<IResponse<IDownloadDocument>> => client.post("/DispatcherOrders/time-coordinate/Excel",
  columns, {
    params
  })

export const coordinateOrderTime = (
  body: IOrderCoordinateTime
): Promise<IResponse<boolean>> => client.put("/DispatcherOrders/coordinate-time", body)

export const changeStatusOrder = (
  body: IOrderChangeStatus
): Promise<IResponse<boolean>> => client.put("/DispatcherOrders/change-status", body)

// export const approveOrder = (
//   body: IOrderApprove
// ): Promise<IResponse<boolean>> => client.put("/DispatcherOrders/approve", body)