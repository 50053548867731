import { useQuery } from "@tanstack/react-query"

import { AUTHORITIES_QUERY_KEY } from "@/constants/query"
import { getAuthorities, getAuthoritiesById } from "@/http/authorities"

import { IRequestParams } from "@/types/api"

export const useAuthoritiesQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getAuthorities(params || {}),
    queryKey: [AUTHORITIES_QUERY_KEY, params],
    retry: false
  })
}

export const useAuthoritiesByIdQuery = (authoritiesId: number) => {
  return useQuery({
    queryFn: () => getAuthoritiesById(authoritiesId),
    queryKey: [AUTHORITIES_QUERY_KEY, authoritiesId],
    retry: false,
    enabled: authoritiesId !== 0
  })
}
