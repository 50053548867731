import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CUSTOMER_QUERY_KEY } from "@/constants/query"
import { createCustomer, deleteCustomers, updateCustomer } from "@/http/customer"

import { useMessage } from "@/hook/useMessage"

export const useCustomerMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(CUSTOMER_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([CUSTOMER_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createCustomer,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateCustomer,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteCustomers,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation
  }
}
