import { useQuery } from "@tanstack/react-query"

import { FUEL_TYPE_QUERY_KEY } from "@/constants/query"
import { getFuelType, getFuelTypeById } from "@/http/fuelType"

import { IRequestParams } from "@/types/api"

export const useFuelTypeQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getFuelType(params || {}),
        queryKey: [FUEL_TYPE_QUERY_KEY, params],
        retry: false
      })
}

export const useFuelTypeByIdQuery = (id: number) => {
    return useQuery({
        queryFn: () => getFuelTypeById(id),
        queryKey: [FUEL_TYPE_QUERY_KEY, id],
        enabled: id !== 0
      })
}
