import { useQuery } from "@tanstack/react-query"

import { DRIVER_QUERY_KEY, DRIVER_FOR_ORDER_QUERY_KEY } from "@/constants/query"
import { getDriver, getDriverById, getDriverForOrder, getDriversSelectList } from "@/http/driver"

import { IRequestParams } from "@/types/api"

export const useDriverQuery = (params?: IRequestParams, customerId?: number) => {
  return useQuery({
    queryFn: () => getDriver(params || {}, customerId),
    queryKey: [DRIVER_QUERY_KEY, params, customerId],
    retry: false
  })
}

export const useDriversSelectListQuery = () => {
  return useQuery({
    queryFn: () => getDriversSelectList(),
    queryKey: [DRIVER_QUERY_KEY]
  })
}

export const useDriverForOrderQuery = (orderId: number, customerId?: number) => {
  return useQuery({
    queryFn: () => getDriverForOrder(orderId, customerId),
    queryKey: [DRIVER_FOR_ORDER_QUERY_KEY, orderId, customerId],
    retry: false
  })
}

export const useDriverByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getDriverById(id),
    queryKey: [DRIVER_QUERY_KEY, id],
    enabled: id !== 0
  })
}
