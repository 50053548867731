import React, { useEffect } from "react"

import { Button, Card, Spin } from "antd"

import { Table } from "@/components/_Shared/Table"
import { Resource, Scope } from "@/constants/permission"

import { financeColumns } from "./FinanceColumns"

import { useFinanceQuery } from "@/hook/useFinanceQuery"
import { useMessage } from "@/hook/useMessage"
import { usePermission } from "@/hook/usePermission"
import { useTable } from "@/hook/useTable"

import { ICustomer } from "@/types/ICustomer"
import { FinanceOperationType, IFinance } from "@/types/IFinance"


import { documentDownload } from "@/utils/download"


export type FinanceTableProps = {
    onGetInvoice(order?: IFinance[]): void;
    customers?: ICustomer[]
}

export const FinanceTable = (props: FinanceTableProps) => {



    const { Error } = useMessage("CUSTOMER_FINANCE")

    const { hasPermission } = usePermission()

    const { columns, pagination, filter, order } = useTable({
        initialColumns: financeColumns,
        key: "FINANCE"
    })

    const { data: orderData, isLoading: orderDataLoading } = useFinanceQuery({
        Page: pagination.page,
        PageSize: pagination.pageSize,
        Filter: props.customers?.some(x => x != undefined) ? `CustomerId = ${props.customers?.map(x => x.id).join(" | CustomerId = ")}` : "",
        OrderBy: order.orderBy
    })

    useEffect(() => {
        if (columns.data.find(x => x.key == "documents") == undefined) {
            columns.data.push(
                {
                    title: "Документы",
                    key: "documents",
                    dataIndex: ["documents"],
                    width: 100,
                    isVisible: true,
                    order: 8,
                    render: (_: any, data) => {
                        return data.operationType !== FinanceOperationType.Cancelled &&
                      <Button type={"primary"} onClick={() => documentDownload(data, Error)}>Скачать</Button>
                    }
                })
        }

    }, [])



    return (
        <Spin spinning={orderDataLoading}>
            { hasPermission(Resource.Finance, Scope.Update) && <Card style={{ marginBottom: "10px" }}>
                <Button type={"primary"} size={"large"} shape="round"
                        onClick={() => props.onGetInvoice(orderData?.response.data)} >Получить счет</Button>
            </Card> }
            <Table
                dataSource={orderData?.response.data}
                columns={columns.data}
                rowKey="id"
                isLoading={orderDataLoading}
                isSelectable={false}
                onSorterChange={order.setOrderBy}
                permission={{
                    resource: Resource.Finance
                }}
                pagination={{
                    currentPage: pagination.page,
                    pageSize: pagination.pageSize,
                    total: orderData?.response.count,
                    onPageChange: pagination.onPageChange,
                    onPageSizeChange: pagination.onPageSizeChange
                }}
            />
        </Spin>
    )
}
