import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "../../_styles/cardById.module.scss"

import { useAuthoritiesByIdQuery } from "@/hook/Authorities/useAuthoritiesPageQuery"

import { paths } from "@/router/paths"

import { formatDate } from "@/utils/formatDate"

const { Item } = Descriptions

const AuthoritiesById: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const navigate = useNavigate()

  const { data, isLoading, isError } = useAuthoritiesByIdQuery(Number(id))

  const onBackHandler = () => {
    navigate(-1)
  }

  const onBackToListHandler = () => {
    navigate(paths.authorities)
  }

  if (isError) {
    return <Empty description="Внутренняя доверенность не найдена" />
  }

  return (
    <Skeleton loading={isLoading}>
      {data && (
        <>
          <Descriptions
            title={`Внутренняя доверенность ${data.response.number}`}
            layout="vertical"
            bordered
          >
            <Item label="Дата выдачи">{formatDate(data.response.date)}</Item>
            <Item label="Номер доверенности">{data.response.number}</Item>
            <Item label="Номер заказа">{data.response.orderAuthorities.orderNumber}</Item>
            <Item label="ФИО">{data.response.driver.fullName}</Item>
            <Item label="Контрагент">{data.response.orderAuthorities.customerName}</Item>
            <Item label="Поставщик">{data.response.provider.name}</Item>
            <Item label="Выдал">
              {data.response?.issuedName ?? "-"}
            </Item>
          </Descriptions>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад</Button>
            <Button onClick={onBackToListHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
  )
}

export default AuthoritiesById
