import { Key } from "react"

import { List, Space, Switch } from "antd"

import { ITableColumn } from "@/types/ui"


interface FieldVisibilityProps<T> {
  columns: ITableColumn<T>[]
  toggleColumnVisible: (key: Key) => void
}

export const FieldVisibility = <T extends object>(props: FieldVisibilityProps<T>) => {
  const { columns, toggleColumnVisible } = props

  return (
    <List>
      {columns.map((column) => (
        <List.Item key={column.key}>
          <Space>
            <Switch checked={column.isVisible} onChange={() => toggleColumnVisible(column.key)} />
            <span>{column.title}</span>
          </Space>
        </List.Item>
      ))}
    </List>
  )
}