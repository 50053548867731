import { client } from "@/http/client"

import { IPagingResponse, IRequestParams } from "@/types/api"
import { IOrderReport, IOrderReportOption } from "@/types/IOrderReport"

export const getOrderReport = (
  params?: IOrderReportOption
): Promise<IPagingResponse<IOrderReport>> =>
  client.get("/OrderReports", {
    params
  })
