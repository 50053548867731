import { FC } from "react"

import { Table } from "antd"

import { getTransformedFuelDensity, convertFuelAmountToLitres } from "@/types/IFuel"
import { IOrderFuel } from "@/types/IOrder"

import { formatNumber, formatFuelAmount, getMoney, formatFuelVolume } from "@/utils/formatNumber"


export interface OrderFuelTableProps {
  fuels: IOrderFuel[] | undefined
  additionalColumns?: any[]
}

export const OrderFuelTable: FC<OrderFuelTableProps> = (props) => {
  const { fuels, additionalColumns } = props

  const fuelColumns = [
    {
      title: "Тип топлива",
      dataIndex: "fuelType",
      key: "fuelType",
      isVisible: true
    },
    {
      title: "Литры",
      dataIndex: "amount",
      key: ("amount"),
      render: (_: any, fuel: IOrderFuel) => `${Math.round(convertFuelAmountToLitres(fuel.amount, fuel.density)).toLocaleString()}`,
      isVisible: true
    },
    {
      title: "Плотность",
      dataIndex: "density",
      key: "density",
      render: (_: any, fuel: IOrderFuel) => getTransformedFuelDensity(fuel.density),
      isVisible: true
    },
    {
      title: "Тонны",
      dataIndex: "amount",
      key: "amount",
      render: (_: any, fuel: IOrderFuel) => `${fuel.amount.toFixed(3)}`,
      isVisible: true
    },
    {
      title: "Цена за тонну",
      dataIndex: "price",
      key: "price",
      render: (_: any, fuel: IOrderFuel) => `${getMoney(parseFloat(formatNumber(fuel.price)))} ${fuel.isFuture ? " (среднее значение)" : ""}`
    },
    {
      title: "Сумма",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (_: any, fuel: IOrderFuel) => getMoney(fuel.price * fuel.amount),
      isVisible: true
    }
  ]

  return (
    <Table
      columns={[...fuelColumns.filter(c=>c.isVisible), ...(additionalColumns ?? [])]}
      rowKey={(fuel) => fuel.id}
      dataSource={fuels}
      pagination={false}
    />
  )
}
