import { CSSProperties, ReactNode } from "react"

import { MenuOutlined } from "@ant-design/icons"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Button, List, Space, Typography } from "antd"

interface SortableItemProps {
  id: number
  name: ReactNode
}

export const SortableItem = (props: SortableItemProps) => {
  const { id, name } = props

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({
    id: String(id)
  })

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: "#fff",
    padding: 12
  }

  return (
    <List.Item ref={setNodeRef} style={style} {...attributes}>
      <Space>
        <Button
          ref={setActivatorNodeRef}
          {...listeners}
          type="text"
          icon={<MenuOutlined />}
        />
        <Typography>
          {name}
        </Typography>
      </Space>
    </List.Item>
  )
}