import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { IFinance, IFinanceCreate, IFinanceForGenerate } from "@/types/IFinance"
import { IInvoiceGenerate } from "@/types/IInvoice"

export const getFinance = (
  params: IRequestParams
): Promise<IPagingResponse<IFinance>> =>
  client.get("/Finance", {
    params
  })

export const getFinanceByOrderId = (id: number): Promise<IResponse<IFinance>> =>
  client.get(`/Finance/Order/${id}`)

export const getFinancesForGenerate = (customerIds: number[]): Promise<IResponse<IFinanceForGenerate[]>> => {
  const urlSearchParams = new URLSearchParams()
  customerIds.forEach(id => urlSearchParams.append("customerIds", id.toString()))

  return client.get("/Finance/for-generate?" + urlSearchParams)
}

export const generateInvoice = (data: IInvoiceGenerate): Promise<IResponse<void>> =>
  client.patch(`Finance/Invoice/${data.financeId}`, data)

export const createFinance = (
  body: IFinanceCreate
): Promise<IResponse<number>> => client.post("/Finance", body)

export const getInvoice = (
  financeId: number
): Promise<IResponse<IDownloadDocument>> =>
  client.get(`/Finance/Invoice/${financeId}`)

// todo на бэке этот эндпоинт должен принимать InvoiceDto, но надо уточнить пока, нужно ли это вообще
// export const sendInvoiceEmail = (
//   financeId: number,
//   email: string
// ): Promise<IResponse<void>> =>
//   client.post("/Finance/Invoice/SendEmail", { financeId, email })
