import { FC, Key, useRef } from "react"

import { useNavigate } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"
import { FuelLimitAddForm, FuelLimitAddFormProps } from "@/components/Fuel/FuelLimitAddForm"
import { Resource } from "@/constants/permission"
import { getFuelLimitsExcel } from "@/http/fuelLimit"
import { FuelLimitsColumns } from "@/pages/FuelLimits/FuelLimitsColumns"
import { useFuelLimitsFilters } from "@/pages/FuelLimits/FuelLimitsFilters"

import { useFuelLimitsMutation } from "@/hook/FuelLimits/useFuelLimitsMutation"
import { useFuelLimitsQuery } from "@/hook/FuelLimits/useFuelLimitsQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { FUEL_LIMITS_KEY, IFuelLimit } from "@/types/IFuelLimit"

import { paths } from "@/router/paths"

import { getInitialColumns } from "@/utils/getInitialColumns"


export const FuelLimitsPage: FC = () => {
  const navigate = useNavigate()
  const { filters } = useFuelLimitsFilters()
  const fuelLimitAddComponent = useRef<FuelLimitAddFormProps>(null)

  const { deleteMutation } = useFuelLimitsMutation()

  const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getFuelLimitsExcel({
    Filter: filter.filter,
    OrderBy: order.orderBy
  }, getInitialColumns(FuelLimitsColumns, FUEL_LIMITS_KEY)))

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(FuelLimitsColumns, FUEL_LIMITS_KEY),
    key: FUEL_LIMITS_KEY
  })

  const { data, isLoading } = useFuelLimitsQuery({
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })

  const onRowClickHandler = (data: IFuelLimit) => {
    navigate(paths.fuelLimitById(data.id))
  }

  const onAddHandler = () => {
    fuelLimitAddComponent.current?.open()
  }

  const onEditHandler = (id: number) => {
    fuelLimitAddComponent.current?.open(id)
  }

  const onRemoveHandler = async (ids: Key[]) => {
    await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  return (
    <>
      <FuelLimitAddForm ref={fuelLimitAddComponent} />
      <Table
        dataSource={data?.response.data}
        columns={columns.data}
        onUpdateColumns={columns.onColumnChange}
        rowKey="id"
        isLoading={isLoading}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: FUEL_LIMITS_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        addText="Добавить"
        onRowClick={onRowClickHandler}
        onSorterChange={order.setOrderBy}
        permission={{
            resource: Resource.Fuel
        }}
      />
    </>
  )
}
