import { useQuery } from "@tanstack/react-query"

import { TRANSPORT_TYPE_QUERY_KEY } from "@/constants/query"
import { getTransportTypes } from "@/http/transportType"

import { IRequestParams } from "@/types/api"

export const useTransportTypesQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getTransportTypes(params || {}),
        queryKey: [TRANSPORT_TYPE_QUERY_KEY, params],
        retry: false
      })
}