import { OidcClient } from "@axa-fr/oidc-client"
import axios from "axios"

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
})

client.interceptors.request.use(
  (config) => {
    const { tokens } = OidcClient.get()

    if (tokens?.accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const UNAUTHORIZED = 401

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error.response?.status
//todo: для подвисшего юзера запросы не отдают статус, хотя возвращаются с 401 ошибкой
    if (status === UNAUTHORIZED) {
      sessionStorage.clear()
      const oidc = OidcClient.get()
      oidc.logoutAsync()
    }
    else
      return Promise.reject(error)
  }
)
