import { client } from "@/http/client"

import { IRequestParams, IResponse } from "@/types/api"
import { IPermission } from "@/types/IPermission"
import { IResource, IScope } from "@/types/IResource"

export const getResources = (
  params: IRequestParams
): Promise<IResponse<IResource[]>> =>
  client.get("/Permissions/Resources", {
    params
  })

export const getScopes = (
  params: IRequestParams
): Promise<IResponse<IScope[]>> =>
  client.get("/Permissions/Scopes", {
    params
  })

export const getPermissions = (): Promise<IResponse<IPermission[]>> =>
  client.get("/Permissions")