import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CUSTOMER_CATEGORY_QUERY_KEY } from "@/constants/query"
import { createCustomerCategory, deleteCustomerCategories, editFuelSettingsCategory, updateCustomerCategory } from "@/http/customerCategory"

import { useMessage } from "@/hook/useMessage"

export const useCustomerCategoryMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(CUSTOMER_CATEGORY_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([CUSTOMER_CATEGORY_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createCustomerCategory,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateCustomerCategory,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteCustomerCategories,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const editFuelSettingsMutation = useMutation({
    mutationFn: editFuelSettingsCategory,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    editFuelSettingsMutation
  }
}
