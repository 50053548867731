import { useEffect, useState } from "react"

import { useSearchParams } from "react-router-dom"

import { useFilterStorage } from "@/hook/useFilterStorage"

import { ITableColumn } from "@/types/ui"

interface ITableType<T> {
  pagination: ITablePaginationType
  filter: ITableFilterType
  columns: ITableColumnType<T>
  order: ITableOrderType
  updateColumns: (newColumns: ITableColumn<T>[]) => void
}

interface ITablePaginationType {
  page: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (current: number, pageSize: number) => void
}

interface ITableFilterType {
  filter: string
  onFilterChange: (filter: string) => void
  onFilterReset: () => void
}

interface ITableColumnType<T> {
  data: ITableColumn<T>[]
  onColumnChange: (columns: ITableColumn<T>[]) => void
}

interface ITableOrderType {
  orderBy: string
  setOrderBy: (value: string) => void
}

interface UseTableOptions<T> {
  initialColumns: ITableColumn<T>[]
  key?: string
}

export const useTable = <T extends object>(options: UseTableOptions<T>): ITableType<T> => {
  const { initialColumns, key } = options

  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState("")
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
  const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 50)
  const [columns, setColumns] = useState<ITableColumn<T>[]>(initialColumns)
  const [orderBy, setOrderBy] = useState<string>("")


  const { saveFilterString, resetFilter, getFilterString } = useFilterStorage({
    key: key || ""
  })

  const updateColumns = (newColumns: ITableColumn<T>[]) => {
    setColumns(newColumns)
  }

  const setQueryParams = (name: string, value: string) => {
    searchParams.set(name, value)
    setSearchParams(searchParams)
  }

  const onFilterChange = (filter: string) => {
    setFilter(filter)
    onPageChange(1)
    if (key) {
      saveFilterString(filter)
    }
  }

  const onFilterReset = () => {
    setFilter("")
    onPageChange(1)

    if (key) {
      resetFilter()
    }
  }

  const onPageChange = (page: number) => {
    setPage(page)
    setQueryParams("page", page.toString())
  }

  const onPageSizeChange = (current: number, pageSize: number) => {
    setPageSize(pageSize)
    setQueryParams("pageSize", pageSize.toString())
  }

  const onColumnChange = (columns: ITableColumn<T>[]) => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(columns))
    }
    setColumns([...columns])
  }

  useEffect(() => {
    if (!key) {
      return
    }

    const oldFString = getFilterString()

    if (oldFString) {
      setFilter(oldFString)
    }
  }, [getFilterString, key])

  return {
    pagination: {
      pageSize,
      page,
      onPageChange,
      onPageSizeChange
    },
    filter: {
      filter,
      onFilterChange,
      onFilterReset
    },
    columns: {
      data: columns,
      onColumnChange: onColumnChange
    },
    order: {
      orderBy,
      setOrderBy
    },
    updateColumns
  }
}
