import { useQuery } from "@tanstack/react-query"

import { FUEL_QUOTES_QUERY_KEY, FUEL_QUOTES_SETTINGS_QUERY_KEY } from "@/constants/query"
import { getFuelQuoteById, getFuelQuoteCardById, getFuelQuotesArchive, getFuelQuotesByFuel } from "@/http/fuelQuote"

import { IRequestParams } from "@/types/api"

export const useFuelQuotesByFuelQuery = (fuelId: number, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getFuelQuotesByFuel(fuelId, params || {}),
    queryKey: [FUEL_QUOTES_QUERY_KEY, params]
  })
}

export const useFuelQuoteByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getFuelQuoteById(id),
    queryKey: [FUEL_QUOTES_QUERY_KEY, id],
    enabled: id !== 0
  })
}

export const useFuelQuoteCardById = (id: number) => {
  return useQuery({
    queryFn: () => getFuelQuoteCardById(id),
    queryKey: [FUEL_QUOTES_SETTINGS_QUERY_KEY, id],
    enabled: id !== 0
  })
}

export const useFuelQuoteArchiveQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getFuelQuotesArchive(params || {}),
    queryKey: [FUEL_QUOTES_QUERY_KEY, params]
  })
}
