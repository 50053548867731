import { FC, useEffect, useState } from "react"

import { Flex, Row, Typography } from "antd"
import { CartesianGrid, Legend, Line, LineChart, Pie, PieChart, Tooltip as ChartTooltip, XAxis, YAxis } from "recharts"

import { Table } from "@/components/_Shared/Table"
import { AnalyticsBarChart } from "@/components/Analytics/AnalyticsBarChart"
import { AnalyticsLineChart } from "@/components/Analytics/AnalyticsLineChart"
import { AnalyticsPieChart } from "@/components/Analytics/AnalyticsPieChart"
import {
  useCustomerAnalyticsHeader
} from "@/components/Analytics/AnalyticsSettings/CustomerAnalytics/useCustomerAnalyticsHeader"
import { Resource } from "@/constants/permission"
import { getAnalyticsRefusalExcel } from "@/http/analytics"

import { orderRefusalColumns } from "./AnalyticsRefusalPageColumns"
import classes from "./RefusalAnalyticsPage.module.scss"

import { useRefusalsAnalyticsQuery } from "@/hook/Analytics/useAnalyticsQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { IAnalyticsPageDisplayUnit, IRefusalAnalytics } from "@/types/IAnalytics"
import { IDictionary, ITableColumn } from "@/types/ui"

const { Title } = Typography

const displayUnits = [
  {
    value: "refusalCount",
    label: "Количество",
    bigLabel: "Количество отказов"
  },
  {
    value: "refusalAmount",
    label: "Тонны",
    bigLabel: "Объем отказов (Тонны)"
  }
]

const additionalDataKeys: IDictionary<IAnalyticsPageDisplayUnit> = {
  "refusalCount": {
    value: "orderCount",
    label: "",
    bigLabel: "Количество заказов"
  },
  "refusalAmount": {
    value: "orderAmount",
    label: "",
    bigLabel: "Объем заказов (Тонны)"
  },
  "deliverySum": {
    value: "pickupSum",
    label: "",
    bigLabel: "Сумма самовывозов"
  },
  "deliverySumMillions": {
    value: "pickupSumMillions",
    label: "",
    bigLabel: "Сумма самовывозов (млн)"
  }
}

const AnalyticsRefusalPage: FC = () => {
  const { optionsList, component: HeaderComponent, displayUnit, isComparison } = useCustomerAnalyticsHeader({
    baseDisplayUnits: [],
    additionalDisplayUnits: displayUnits
  })

  const additionalDataKey = additionalDataKeys[displayUnit.value]

  const [initialColumns, setInitialColumns] =
    useState<ITableColumn<IRefusalAnalytics>[]>(orderRefusalColumns(true))

  const [analyticsMapped, setAnalyticsMapped] = useState<IRefusalAnalytics[]>([])

  const { columns, pagination, filter, order } = useTable({
    initialColumns: initialColumns
  })

  useEffect(() => {
    setInitialColumns(orderRefusalColumns(!isComparison))
  }, [isComparison])

  const { data: analyticsRefusalData, isLoading: isLoadingAnalyticsRefusal } = useRefusalsAnalyticsQuery({
      optionsList
    },
    {
      Page: pagination.page,
      PageSize: pagination.pageSize,
      Filter: filter.filter,
      OrderBy: order.orderBy
    })

  useEffect(() => {
    if (analyticsRefusalData?.response.data) {
      setAnalyticsMapped(analyticsRefusalData.response.data.map(r => ({
        rowKey: crypto.randomUUID(),
        period: r.period,
        refusalCount: r.refusalCount,
        refusalAmount: r.refusalAmount,
        orderCount: r.orderCount,
        orderAmount: r.orderAmount
      })))
    }
  }, [analyticsRefusalData])

  const forOnlyRefusalDiagramData = analyticsRefusalData?.response.data.filter(r => r.refusalCount > 0)

  const { download: excelDownload, isLoading: isExcelLoading } =
    useDownloadFile(() =>
      getAnalyticsRefusalExcel({
          optionsList
        },
        columns.data,
        {
          Page: pagination.page,
          PageSize: pagination.pageSize,
          Filter: filter.filter,
          OrderBy: order.orderBy
        })
    )

  return (
    <div>
      <Title className={classes.titile} level={3}>Динамика отказов</Title>
      {
        HeaderComponent
      }
      <Title level={4}>Отказы за период</Title>
      <Row>
        <Flex gap="middle" align="middle" justify="center">
          {
            !isComparison &&
              <AnalyticsLineChart dataKeys={[displayUnit]} data={forOnlyRefusalDiagramData}/>
          }
          {
            isComparison &&
              <AnalyticsBarChart
                  dataKeys={[displayUnit]}
                  data={forOnlyRefusalDiagramData}/>
          }
          <AnalyticsPieChart
            chartWidth={730}
            chartHeight={400}
            dataKey={displayUnit.value}
            name={displayUnit.bigLabel}
            data={forOnlyRefusalDiagramData}
            nameKey="refusalCount"
            fill="#8884d8"/>
        </Flex>
      </Row>
      <Title level={4}>Отказы в сравнении с количеством заказов за период</Title>
      <Row>
        <Flex gap="middle" align="middle" justify="center">
          {
            !isComparison &&
              <LineChart
                  data={analyticsMapped}
                  width={700}
                  height={400}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                  <XAxis dataKey="period"/>
                  <YAxis/>
                  <ChartTooltip/>
                  <Legend/>
                  <CartesianGrid stroke="#f5f5f5"/>
                  <Line type="monotone" name={displayUnit.bigLabel}
                        dataKey={displayUnit.value}
                        stroke="#387908"/>
                  <Line type="monotone" name={additionalDataKey.bigLabel}
                        dataKey={additionalDataKey.value}/>
              </LineChart>
          }
          {
            isComparison &&
              <AnalyticsBarChart
                  dataKeys={[displayUnit, additionalDataKey]}
                  data={analyticsMapped}/>
          }
          <PieChart width={730} height={400}>
            <Pie
              data={analyticsMapped}
              dataKey={displayUnit.value}
              nameKey="refusalCount"
              fill="#82ca9d"
              cx="50%" cy="50%"
              outerRadius={100}
              label/>
            <Pie
              data={analyticsMapped}
              dataKey={additionalDataKey.value}
              nameKey="refusalCount"
              innerRadius={150}
              cx="50%" cy="50%"
              outerRadius={180}
              fill="#8884d8"
              label/>
          </PieChart>
        </Flex>
      </Row>
      <Row>
        <Table
          dataSource={analyticsMapped}
          columns={columns.data}
          download={{
            onDownload: excelDownload,
            isLoading: isExcelLoading
          }}
          isSelectable={false}
          rowKey="rowKey"
          isLoading={isLoadingAnalyticsRefusal}
          onUpdateColumns={columns.onColumnChange}
          pagination={{
            currentPage: pagination.page,
            pageSize: pagination.pageSize,
            total: analyticsRefusalData?.response.count,
            onPageChange: pagination.onPageChange,
            onPageSizeChange: pagination.onPageSizeChange
          }}
          onSorterChange={order.setOrderBy}
          permission={{
            resource: Resource.RefusalsAnalytics
          }}
        />
      </Row>
    </div>
  )
}

export default AnalyticsRefusalPage