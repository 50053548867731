import { FC, createContext, useState } from "react"

import { Resource, Scope } from "@/constants/permission"

import { usePermission } from "@/hook/usePermission"

import { ICustomer } from "@/types/ICustomer"

interface ICustomerContext {
  customer: ICustomer | undefined
  isCustomer: () => boolean
  setCustomer: (customer: ICustomer | undefined) => void
}

const defaultState: ICustomerContext = {
  customer: undefined,
  isCustomer: () => false,
  setCustomer: () => {}
}

const CustomerContext = createContext<ICustomerContext>(defaultState)


const CustomerContextProvider: FC<any> = ({ children }) => {
  const { hasPermission } = usePermission()
  const [customer, setCustomer] = useState<ICustomer>()

  const setCurrentCustomer = (customer: ICustomer | undefined) => {
    setCustomer(customer)
  }

  const isCustomer = () => {
    return hasPermission(Resource.Account, Scope.Menu)
  }

  return (
    <CustomerContext.Provider
      value={{
        customer: customer,
        isCustomer: isCustomer,
        setCustomer: setCurrentCustomer
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}
export { CustomerContext, CustomerContextProvider }
