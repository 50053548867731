import React, { createContext, FC, useContext, useEffect, useState } from "react"

import { useDispatcherTimeCoordinateCountQuery } from "@/hook/DispatcherOrders/useDispatcherOrdersQuery"

interface TimeCoordinateContextProps {
  countTimeCoordinateOrders: number;
  refetchTimeCoordinateOrders: () => void;
}

const TimeCoordinateContext = createContext<TimeCoordinateContextProps | undefined>(undefined)

export const useTimeCoordinateContext = () => {
  const context = useContext(TimeCoordinateContext)
  if (!context) {
    throw new Error("Контекст уведомлений не доступен")
  }
  return context
}

export const TimeCoordinateProvider: FC<any> = ({ children }) => {
  const [countTimeCoordinateOrders, setCountTimeCoordinateOrders] = useState<number>(0)

  const { data, refetch } = useDispatcherTimeCoordinateCountQuery()

  useEffect(() => {
    setCountTimeCoordinateOrders(data?.response ?? 0)
  }, [data])

  const refetchTimeCoordinateOrders = () => {
    refetch()
  }

  return (
    <TimeCoordinateContext.Provider value={{ countTimeCoordinateOrders, refetchTimeCoordinateOrders }}>
      {children}
    </TimeCoordinateContext.Provider>
  )
}
