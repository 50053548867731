import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CUSTOMER_CLIENT_QUERY_KEY } from "@/constants/query"
import { createCustomerClient, deleteCustomerClients, updateCustomerClient } from "@/http/customerClient"

import { useMessage } from "@/hook/useMessage"

export const useCustomerClientMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(CUSTOMER_CLIENT_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([CUSTOMER_CLIENT_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createCustomerClient,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateCustomerClient,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteCustomerClients,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation
  }
}
