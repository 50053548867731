import { useQuery } from "@tanstack/react-query"

import {
  ANALYTICS_DELIVERIES_PICKUPS_GET_QUERY_KEY,
  ANALYTICS_ORDERS_GET_QUERY_KEY,
  ANALYTICS_PRICES_COMPARISON_PERIOD_QUERY_KEY,
  ANALYTICS_PRICES_GET_QUERY_KEY,
  ANALYTICS_REFUSALS_GET_QUERY_KEY,
  ANALYTICS_SALES_GET_QUERY_KEY
} from "@/constants/query"
import {
  getAnalyticsDeliveriesPickups,
  getAnalyticsPrice,
  getAnalyticsRefusal,
  getOrderAnalytics,
  GetPeriodForComparison,
  getSalesAnalytics
} from "@/http/analytics"

import { IRequestParams } from "@/types/api"
import { AnalyticsPeriod } from "@/types/IAnalytics"
import { IAnalyticsByCustomerParams, IAnalyticsParams } from "@/types/IAnalytics"


export const useOrderAnalyticsQuery = (analyticsParams: IAnalyticsByCustomerParams, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getOrderAnalytics(analyticsParams, params),
    queryKey: [ANALYTICS_ORDERS_GET_QUERY_KEY, analyticsParams, params],
    retry: false
  })
}

export const useSalesAnalyticsQuery = (analyticsParams: IAnalyticsParams, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getSalesAnalytics(analyticsParams, params),
    queryKey: [ANALYTICS_SALES_GET_QUERY_KEY, analyticsParams, params],
    retry: false
  })
}

export const usePricesAnalyticsQuery = (params: IAnalyticsByCustomerParams) => {
  return useQuery({
    queryFn: () => getAnalyticsPrice(params),
    queryKey: [ANALYTICS_PRICES_GET_QUERY_KEY, params],
    retry: false
  })
}

export const useRefusalsAnalyticsQuery = (option: IAnalyticsByCustomerParams, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getAnalyticsRefusal(option, params),
    queryKey: [ANALYTICS_REFUSALS_GET_QUERY_KEY, option, params],
    retry: false
  })
}

export const useDeliveriesPickupsAnalyticsQuery = (option: IAnalyticsByCustomerParams, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getAnalyticsDeliveriesPickups(option, params),
    queryKey: [ANALYTICS_DELIVERIES_PICKUPS_GET_QUERY_KEY, option, params],
    retry: false
  })
}


export const useAnalyticsComparisonPeriodQuery = (period: AnalyticsPeriod) => {
  return useQuery({
    queryFn: () => GetPeriodForComparison(period),
    queryKey: [ANALYTICS_PRICES_COMPARISON_PERIOD_QUERY_KEY, period],
    retry: false
  })
}