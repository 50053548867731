import React from "react"

import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntDatePickerProps
} from "antd"
//import IMask from "imask"
//import moment from "moment"

const DATE_FORMAT = "DD.MM.YYYY"
// TODO: после обновления пакета Antd до 5.14.х onKeyDown перестал работать
// const MASKED = IMask.createMask({
//   blocks: {
//     DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
//     MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
//     YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE }
//   },
//   format: (date: Date) => moment(date).format(DATE_FORMAT),
//   mask: Date,
//   parse: (date: string) => moment(date, DATE_FORMAT),
//   pattern: DATE_FORMAT
// })

export type DatePickerProps = Omit<AntDatePickerProps, "format" | "picker" | "onKeyDown">

export const DatePicker = (props: DatePickerProps) => (
  <AntdDatePicker
    format={DATE_FORMAT}
    // onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
    //   const input = event.target as HTMLInputElement
    //   input.value = MASKED.resolve(input.value)
    // }}
    picker="date"
    placeholder={DATE_FORMAT.toLowerCase()}
    {...props}
  />
)