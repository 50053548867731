import { FC,useEffect ,createContext,useState, useContext, useMemo }  from "react"

import { OidcClient } from "@axa-fr/oidc-client"
import { HubConnection, HubConnectionBuilder }  from "@microsoft/signalr"

import { useCustomerQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"

const SignalRContext = createContext<HubConnection | null >(null)

export const SignalRProvider:FC<any> = ({ children }) =>{
    const [connection, setConnection] = useState<HubConnection | null>(null)
    const { tokens } = OidcClient.get()

    const connectionHub = async () => {
        const newConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/notification`,{ accessTokenFactory: ()=> tokens.accessToken })
        .withAutomaticReconnect()
        .build()
        
        try {
            await newConnection.start()
            setConnection(newConnection)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect( ()=> { connectionHub() },[])

    window.addEventListener("beforeunload", async () => await connection?.stop())
        

    return (
        <SignalRContext.Provider value={connection}>
            {children}
        </SignalRContext.Provider>
    )
} 


export const useSignalR = () => {
    return useContext(SignalRContext)
  }
