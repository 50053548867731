export interface ICustomerCategory {
	id: number
	name: string
	percent: number
}

export interface ICustomerCategoryCard {
	id: number
	name: string
	percent: number
	fuelSettings: ICategoryFuelSetting[]
}

export interface ICategoryFuelSetting {
	addonPrice: number
	depotId: number
	depotName: string
	fuelCategorySettingId: number
	fuelId: number
	fuelTypeName: string
}

export interface ICustomerCategoryCreate {
	name: string
	percent: number
}

export interface ICustomerCategoryUpdate extends ICustomerCategoryCreate {
	id: number
}

export interface ICategoryFuelSettingRequest {
	id: number
	fuelSettings: ICategoryFuelSettingRequestItem[]
}

export interface ICategoryFuelSettingRequestItem {
	addonPrice: number
	depotId: number
	fuelCategorySettingId?: number
	fuelId: number
}

export const CUSTOMER_CATEGORIES_KEY = "CUSTOMER_CATEGORIES_KEY"
