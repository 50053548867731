import { useQuery } from "@tanstack/react-query"

import { ORDER_REPORT_QUERY_KEY } from "@/constants/query"
import { getOrderReport } from "@/http/orderReport"

import { IOrderReportOption } from "@/types/IOrderReport"

export const useOrderReportQuery = (params: IOrderReportOption) => {
    return useQuery({
        queryFn: () => getOrderReport(params),
        queryKey: [ORDER_REPORT_QUERY_KEY, params],
        retry: false
      })
}
