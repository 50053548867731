import { ICustomer } from "./ICustomer"
import { ICustomerCategory } from "./ICustomerCategory"

export interface ICustomerClient {
	id: number
	name: string
	responsiblePersonName: string
	phone: string
	customerCategoryId: number
	customerCategory: ICustomerCategory
	customers: ICustomer[]
}

export interface ICustomerClientCreate {
	name: string
	responsiblePersonName: string
	phone: string
	customerCategoryId: number
}

export interface ICustomerClientUpdate extends ICustomerClientCreate {
	id: number
}

export const CUSTOMER_CLIENTS_KEY = "CUSTOMER_CLIENTS_KEY"
