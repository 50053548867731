import { useQuery } from "@tanstack/react-query"

import { CUSTOMER_CLIENT_QUERY_KEY } from "@/constants/query"
import { getCustomerClients, getCustomerClientById, getCustomerClientsSelectList } from "@/http/customerClient"

import { IRequestParams } from "@/types/api"

export const useCustomerClientsQuery = (params?: IRequestParams) => {
  return useQuery({
      queryFn: () => getCustomerClients(params || {}),
      queryKey: [CUSTOMER_CLIENT_QUERY_KEY, params],
      retry: false
    })
}

export const useCustomerClientByIdQuery = (id: number) => {
  return useQuery({
      queryFn: () => getCustomerClientById(id),
      queryKey: [CUSTOMER_CLIENT_QUERY_KEY, id],
      enabled: id !== 0
    })
}

export const useCustomerClientsSelectListQuery = () => {
  return useQuery({
      queryFn: () => getCustomerClientsSelectList(),
      queryKey: [CUSTOMER_CLIENT_QUERY_KEY]
    })
}
