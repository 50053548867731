import { client } from "@/http/client"

import { IResponse, IPagingResponse, IRequestParams } from "@/types/api"
import { IDepot, IDepotCreate, IDepotUpdate } from "@/types/IDepot"

export interface IDepotForOrderParam {
  Date: Date
}

export const getDepots = (params: IRequestParams): Promise<IPagingResponse<IDepot>> =>
  client.get("/Depots", {
    params
  })

export const getDepotsSelectList = (): Promise<IResponse<IDepot[]>> =>
  client.get("/Depots/selectList")

export const getDepotById = (id: number): Promise<IResponse<IDepot>> =>
    client.get(`/Depots/${id}`)

export const createDepot = (
    body: IDepotCreate
) : Promise<IResponse<number>> => client.post("/Depots", body)

export const updateDepot = (
    body: IDepotUpdate
) : Promise<IResponse<number>> => client.put("/Depots", body)