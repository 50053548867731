import { ICustomerCategory } from "@/types/ICustomerCategory"
import { ITableColumn } from "@/types/ui"

export const customerCategoryColumns: ITableColumn<ICustomerCategory>[] = [
  {
    title: "Код категории",
    key: "id",
    dataIndex: ["id"],
    align: "center",
    width: 150,
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Название",
    key: "name",
    dataIndex: ["name"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Процента объема",
    key: "percent",
    dataIndex: ["percent"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 3,
    render: (_, record: ICustomerCategory) => { return record.percent? record.percent+"%" : ""}
  }
]
