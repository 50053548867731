import { FC, useEffect, useState } from "react"

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Table, Button, TimePicker, Switch, Space, Form } from "antd"
import moment from "moment"

import { useTimeUnloadFuelMutation } from "@/hook/TimeUnloadFuel/useTimeUnloadFuelMutation"
import { useAllTimeUnloadFuelByDateQuery } from "@/hook/TimeUnloadFuel/useTimeUnloadFuelQuery"

import { ITimeUnloadFuel } from "@/types/ITimeUnloadFuel"

import type { ColumnsType } from "antd/es/table"

export interface ITimeUnalodFuelTableProps {
    date:Date
    isUploadComplete?:boolean
    setiIsUploadComplete?:( isUploadComplete: boolean) => void
}

export const TimeUnloadFuelTable: FC<ITimeUnalodFuelTableProps> = (props) => {
  const { date,isUploadComplete, setiIsUploadComplete } = props
  
  const [newSlot, setNewSlot] = useState<string | undefined>(undefined)
  const [form] = Form.useForm()

  const { 
    deleteTimeUnloadFuelsMutation,
    updateAvailableStatusTimeUnloadFuelsMutation,
    createTimeUnloadFuelsMutation } = useTimeUnloadFuelMutation()

  const { data, isLoading, refetch } = useAllTimeUnloadFuelByDateQuery(date) 

  useEffect(()=>{
    refetch()
  },[ date ])

  useEffect(()=>{
    if (isUploadComplete && setiIsUploadComplete) {
      refetch()
      setiIsUploadComplete(false)      
    }
  },[ isUploadComplete ])

  const onDeleteSlot = async (id : number) => {
    await deleteTimeUnloadFuelsMutation.mutateAsync(id)
    refetch()
  }

  const onAvailableStatusSlotChange = async (slotId:number, isAvailable:boolean ) => {
    await updateAvailableStatusTimeUnloadFuelsMutation.mutateAsync({
      slotId: slotId,
      isAvailable: isAvailable
    })
    refetch()
  }

  const onAddSlot = async () => {
    if (newSlot) {
      await createTimeUnloadFuelsMutation.mutateAsync({
        slot: newSlot,
        status: "Не заполнено",
        deliveryDate: date
      })
      form.setFieldValue("slot",undefined)
      setNewSlot(undefined)
      refetch()
    }
  }

  const columns: ColumnsType<ITimeUnloadFuel> = [
    {
      title: "Дата доставки",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (date: Date) => moment(date).format("YYYY-MM-DD")
    },
    {
      title: "Время",
      dataIndex: "slot",
      key: "slot"
    },
    {
      title: "Доступно",
      dataIndex: "isAvailable",
      key: "isAvailable",
      render: (isAvailable: boolean, record: ITimeUnloadFuel) => (
        <Switch checked={isAvailable} onChange={(checked) => onAvailableStatusSlotChange(record.id,checked)} />
      )
    },
    {
      title: "Заполнен",
      dataIndex: "isFilled",
      key: "isFilled",
      render: (isFilled: boolean) => (isFilled ? "Да" : "Нет")
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, record: ITimeUnloadFuel) => (
        <Space>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDeleteSlot(record.id)}
          />
        </Space>
      )
    }
  ]

  return (
    <div>
      <Form form={form}>
        <Table
          dataSource={data?.response ?? []}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
        <Space style={{ marginTop: 16 }}>
          <Form.Item name="slot">
            <TimePicker
              id="slot"
              onChange={(time, timeString) => setNewSlot(time.format("HH:mm"))}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item>
            <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddSlot}
            disabled={!newSlot}
            >
              Добавить время
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  )
}

