import { FC, useRef } from "react"

import { Spin } from "antd"
import { FinanceGenerateInvoice, FinanceGenerateInvoiceProps } from "src/components/Finance/FinanceGenerateInvoice"

import { FinanceTable } from "@/components/Finance/FinanceTable"

import {  useCustomerQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"

import { IFinance } from "@/types/IFinance"



const Finance: FC = () => {
  const invoiceComponent = useRef<FinanceGenerateInvoiceProps>(null)

  const { data: customersData, isLoading: customerDataLoading } = useCustomerQuery()
  const onGetInvoice = (orders?: IFinance[]) => {
    invoiceComponent.current?.open(orders)
  }


  return (
    <Spin spinning={ customerDataLoading }>
      <FinanceGenerateInvoice ref={invoiceComponent}/>
      <FinanceTable
        onGetInvoice = {onGetInvoice}
        customers={customersData?.response.data}/>
    </Spin>
  )
}
export default Finance
