import React, { FC, Fragment, useState } from "react"

import { Button, DatePicker, Drawer, Form, Input, Select, Space } from "antd"

import { CustomRangePicker } from "../CustomRangePicker"

import { useFilterStorage } from "@/hook/useFilterStorage"

import {
  FilterFieldOperator,
  FilterFieldType,
  IFilterProps,
  IFilterSelectField
} from "@/types/ui"

import { filterSelectOption } from "@/utils/filterSelectOption"

interface FilterWrapperProps extends IFilterProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export interface FieldData {
  name: string | number | (string | number)[]
  value?: any
}

const numberOperators = [
  {
    id: FilterFieldOperator.Equal,
    name: "равно"
  },
  {
    id: FilterFieldOperator.GreaterThanOrEqual,
    name: "больше"
  },
  {
    id: FilterFieldOperator.LessThanOrEqual,
    name: "меньше"
  }
]

export const numberOperatorName = "-numberOperator"

export const FilterWrapper: FC<FilterWrapperProps> = (props) => {
  const [fields, setFields] = useState<FieldData[]>([])
  const [form] = Form.useForm()

  const { open, filters, filterKey, setOpen, onSubmit, onCancel, submitText = "Применить", cancelText = "Сбросить" } = props

  const { saveFilter } = useFilterStorage({
    key: filterKey
  })

  const closeHandler = () => {
    setOpen(false)
  }

  const generateFilters = (data: FieldData[]): string => {
    const expressions: string[][] = []

    for (const field of data) {
      if (field.value == null || field.value === "" || field.name.toString().includes(numberOperatorName)) continue

      const name = (field.name as string[])[0]
      const filter = filters.find((f) => f.name === name)
      if (filter == null) throw new Error("Filter not found")

      const operator = filter?.operator ?? FilterFieldOperator.Equal
      const type = filter.type

      if (Array.isArray(field.value) && type !== FilterFieldType.DateRange) {
        const tempExpression: string[] = []

        for (const exp of field.value) {
          tempExpression.push(transformValue(name, exp, type, operator))
        }

        expressions.push(tempExpression)
      } else {
        expressions.push([transformValue(name, field.value, type, operator)])
      }
    }
    return expressions.join(",")
  }

  const transformValue = (name: string, value: any, type: FilterFieldType, operator: FilterFieldOperator) => {
    switch (type) {
      case FilterFieldType.Date:
        return getStringForDate(name, value, operator) as string
      case FilterFieldType.DateRange:
        return getStringForDateRange(name, value) as string
      case FilterFieldType.Number:
        return `${name} ${transformOperator(fields.find(f => f.name == name + numberOperatorName)?.value ?? operator)} ${value}`
      default:
        return `${name} ${transformOperator(operator)} ${value}`
    }
  }

  const getStringForDate = (name: string, value: any, operator: FilterFieldOperator) => {
    switch (operator) {
      case FilterFieldOperator.LessThan || FilterFieldOperator.LessThanOrEqual:
        return `${name} ${transformOperator(operator)} ${new Date(value).toLocaleDateString("ru-RU")} 23:59:59`
      case FilterFieldOperator.Equal:
        return `(${name} ${transformOperator(FilterFieldOperator.GreaterThanOrEqual)} ${new Date(value).toLocaleDateString("ru-RU")} 00:00:00, `
          + `${name} ${transformOperator(FilterFieldOperator.LessThanOrEqual)} ${new Date(value).toLocaleDateString("ru-RU")} 23:59:59)`
      default:
        return `${name} ${transformOperator(operator)} ${new Date(value).toLocaleDateString("ru-RU")} 00:00:00`
    }
  }

  const getStringForDateRange = (name: string, value: any) => {
    const toDateFilter = `${name} ${transformOperator(FilterFieldOperator.LessThanOrEqual)} ${value[1]
      ? new Date(value[1]).toLocaleDateString("ru-RU")
      : new Date().toLocaleDateString("ru-RU")} 23:59:59`
    return value[0] ? 
      `(${name} ${transformOperator(FilterFieldOperator.GreaterThanOrEqual)} ${new Date(value[0]).toLocaleDateString("ru-RU")} 00:00:00, ${toDateFilter})`
      : toDateFilter
  }

  const transformOperator = (operator: FilterFieldOperator): string => {
    switch (operator) {
      case FilterFieldOperator.Equal:
        return "="
      case FilterFieldOperator.GreaterThan:
        return ">"
      case FilterFieldOperator.LessThan:
        return "<"
      case FilterFieldOperator.Contains:
        return "=*"
      case FilterFieldOperator.GreaterThanOrEqual:
        return ">="
      case FilterFieldOperator.LessThanOrEqual:
        return "<="
      default:
        return "="
    }
  }

  const submitHandler = () => {
    if (onSubmit) {
      onSubmit(generateFilters(fields))
      saveFilter(fields)
    }
  }

  const cancelHandler = () => {
    if (onCancel) {
      form.resetFields()
      setFields([])
      onCancel()
      form.resetFields()
    }
  }

  const onChange = (fields: FieldData[]) => {
    setFields(fields)
  }

  return (
    <Drawer
      open={open}
      onClose={closeHandler}
      footer={
        <Space>
          <Button type="primary" onClick={submitHandler}>
            {submitText}
          </Button>
          <Button onClick={cancelHandler}>{cancelText}</Button>
        </Space>
      }
    >
      <Form
        form={form}
        fields={filters}
        layout="vertical"
        onFieldsChange={(_, allFields) => {
          onChange(allFields)
        }}
      >
        {filters.map((m) => (
          <Fragment key={m.name}>
            {m.type === FilterFieldType.Text && (
              <Form.Item name={m.name} label={m.text}>
                <Input placeholder={m.text} />
              </Form.Item>
            )}
            {m.type === FilterFieldType.Select && (
              <Form.Item name={m.name} label={m.text}>
                <Select
                  allowClear
                  placeholder={m.text}
                  showSearch
                  options={(m as IFilterSelectField)?.values?.map(val => ({
                    value: val.id,
                    label: val.name
                  }))}
                  filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                />
              </Form.Item>
            )}
            {m.type === FilterFieldType.MultiSelect && (
              <Form.Item name={m.name} label={m.text}>
                <Select
                  mode="multiple"
                  placeholder={m.text}
                  showSearch
                  options={(m as IFilterSelectField)?.values?.map(val => ({
                    value: val.id,
                    label: val.name
                  }))}
                  filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                />
              </Form.Item>
            )}
            {m.type === FilterFieldType.Date && (
              <Form.Item name={m.name} label={m.text}>
                <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} placeholder={m.text} />
              </Form.Item>
            )}
            {m.type === FilterFieldType.Number && (
              <Form.Item label={m.text}>
                <Space.Compact style={{ width: "-webkit-fill-available" }}>
                  <Form.Item
                    name={m.name + numberOperatorName}
                    initialValue={m.operator}
                    noStyle>
                    <Select
                      placeholder="Сравнение"
                      options={numberOperators.map(val => ({
                        value: val.id,
                        label: val.name
                      }))}
                      filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                    />
                  </Form.Item>

                  <Form.Item name={m.name}
                    noStyle>
                    <Input placeholder={m.text} />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            )}
            {m.type === FilterFieldType.DateRange && (
              <Form.Item name={m.name} label={m.text}>
                <CustomRangePicker style={{ width: "100%" }}  />
              </Form.Item>
            )}
          </Fragment>
        ))}
      </Form>
    </Drawer>
  )
}
