import { useMemo, useState } from "react"

import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from "@dnd-kit/sortable"
import { List } from "antd"

import { SortableItem } from "@/components/_Shared/Table/FieldOrder/SortableItem"
import { SortableOverlay } from "@/components/_Shared/Table/FieldOrder/SortableOverlay"

import { ITableColumn } from "@/types/ui"

interface FieldOrderProps<T> {
  columns: ITableColumn<T>[];
  onUpdateColumns: (newColumns: ITableColumn<T>[]) => void;
}

export const FieldOrder = <T extends object>(props: FieldOrderProps<T>) => {
  const { columns, onUpdateColumns } = props
  const [active, setActive] = useState<string | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event

    setActive(active.id.toString())
  }

  const activeItem = useMemo(() => {
    return columns.find(column => column.order.toString() === active)
  }, [columns, active])

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over?.id) {
      const activeIndex = columns.findIndex((column) => column.order.toString() === active.id.toString())
      const overIndex = columns.findIndex((column) => column.order.toString() === over.id.toString())
      
      const sortColumns = arrayMove(columns, activeIndex, overIndex)
      onUpdateColumns(sortColumns)
    }
    setActive(null)
  }

  const onDragCancel = () => {
    setActive(null)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={columns.map(column => String(column.order))}>
        <List>
          {columns.map((column) => (
            <SortableItem
              key={column.order}
              id={column.order}
              name={column.title}
            />
          ))}
        </List>
      </SortableContext>
      <SortableOverlay>
        {activeItem
          ? <SortableItem key={activeItem.order} id={activeItem.order} name={activeItem.title}/>
          : null
        }
      </SortableOverlay>
    </DndContext>
  )
}