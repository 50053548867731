import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CUSTOMER_SETTING_QUERY_KEY } from "@/constants/query"
import { updateCustomerSettings } from "@/http/customerSettings"

import { useMessage } from "@/hook/useMessage"

export const useCustomerSettingsMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(CUSTOMER_SETTING_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([CUSTOMER_SETTING_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const updateCustomerSettingsMutation = useMutation({
    mutationFn: updateCustomerSettings,
    onSuccess: () => onSuccess(),
    onMutate:() => onMutate(),
    onError: (e) => onError(e)
  })

  return {  updateCustomerSettingsMutation }
}
