import { useQuery } from "@tanstack/react-query"

import { TRANSPORT_MODELS_QUERY_KEY } from "@/constants/query"
import { getTransportModels } from "@/http/transportModel"

import { IRequestParams } from "@/types/api"

export const useTransportModelsQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getTransportModels(params || {}),
        queryKey: [TRANSPORT_MODELS_QUERY_KEY, params],
        retry: false
      })
}