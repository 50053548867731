import { useQuery } from "@tanstack/react-query"

import { COUNTRY_QUERY_KEY } from "@/constants/query"
import { getCountries, getCountriesSelectList, getCountryById } from "@/http/country"

import { IRequestParams } from "@/types/api"

export const useCountriesQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getCountries(params || {}),
        queryKey: [COUNTRY_QUERY_KEY, params],
        retry: false
      })
}

export const useCountryByIdQuery = (id: number) => {
    return useQuery({
        queryFn: () => getCountryById(id),
        queryKey: [COUNTRY_QUERY_KEY, id],
        enabled: id !== 0
      })
}

export const useCountriesSelectListQuery = () => {
    return useQuery({
        queryFn: () => getCountriesSelectList(),
        queryKey: [COUNTRY_QUERY_KEY]
      })
}