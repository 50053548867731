import { ChangeEvent, FC } from "react"

import { Input, InputProps } from "antd"

import classes from "./NumericInput.module.scss"

interface NumericInputProps extends InputProps {
  min?: number
  max?: number
  maxLength?: number;
  incrementButtons?: boolean;
}

export const NumericInput: FC<NumericInputProps> = (props) => {
  const { value, min, max, maxLength, incrementButtons, onChange, ...otherProps } = props

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if ((maxLength && value.length <= maxLength) || !maxLength)
    onChange?.(event)
  }

  return (
    <Input
      className={!incrementButtons ? classes["hide-increment-buttons"] : ""}
      type="number"
      value={value}
      min={min}
      max={max}
      onChange={onChangeHandler}
      {...otherProps}
    />
  )
}