
import { Space } from "antd"
import { NavLink } from "react-router-dom"

import { ICustomerClient } from "@/types/ICustomerClient"
import { ITableColumn } from "@/types/ui"

import { paths } from "@/router/paths"


export const customerClientColumns: ITableColumn<ICustomerClient>[] = [
  {
    title: "Код клиента",
    key: "id",
    dataIndex: ["id"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 1
  },
  {
    title: "Название",
    key: "name",
    dataIndex: ["name"],
    align: "center",
    //render: (_, customerClient: ICustomerClient) => <NavLink to={paths.customerClientById(customerClient.id)}>{customerClient.name}</NavLink>,
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Категория",
    key: "categoryName",
    dataIndex: ["customerCategory", "name"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Субклиенты",
    key: "customers",
    dataIndex: ["customers"],
    align: "center",
    render: (_, customerClient: ICustomerClient) => (
      <>
        <Space direction="vertical" size="small" >
          {
            customerClient.customers &&
            customerClient.customers.map((customer) => {
              return (
                <NavLink to={paths.customerById(customer.id)}>{customer.name}</NavLink>
              )
            })
          }
        </Space>
      </>
    ),
    sorter: false,
    isVisible: true,
    order: 3
  }
]
