import { FC,useContext,useEffect,useState } from "react"

import { Typography, Checkbox, Table, Select, Form, Flex, Row, Col, Button } from "antd"

import { CustomerContext } from "@/context/CustomerContext"
import { getSettingsByCustomerClientId, updateCustomerSettings } from "@/http/customerSettings"
import { getFuelType, getFuelTypeByDepot } from "@/http/fuelType"

import classes from "./CustomerSettingsPage.module.scss"

import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useFuelTypeQuery } from "@/hook/Dictionaries/useFuelTypeQuery"
import { useCustomerSettingsMutation } from "@/hook/Settings/UseSettingsMutation"

import { IDepot } from "@/types/IDepot"
import { IFuelType } from "@/types/IFuelType"
import { ICustomerNotificaitonSettings, ICustomerSettings } from "@/types/ISettings"
import { ITableColumn } from "@/types/ui"


const { Title } = Typography

const CustomerSettingsPage:FC = ()=>{
    const customerContext = useContext(CustomerContext)
    const [settings, setSettings] = useState<ICustomerSettings>()

    const { updateCustomerSettingsMutation }  = useCustomerSettingsMutation()

    const { data: depotsData } = useDepotSelectListQuery()
    const { data: fuelTypeData } = useFuelTypeQuery()

    const notificaitonSettingsTypeColumns: ITableColumn<ICustomerNotificaitonSettings>[] = [
        {
            title: "Статус",
            dataIndex: "statusText",
            key:"statusText",
            isVisible: true,
            order: 1,
            width: 200
        },
        {
            title: "Push",
            dataIndex: "isPushNotificationEnable",
            key:"isPushNotificationEnable",
            isVisible: true,
            order: 2,
            render: (_,notificaitonSettings) => (
                <Checkbox 
                checked={notificaitonSettings.isPushNotificationEnable}
                onChange={ (e)=>{updateNotificationSettings(notificaitonSettings.id,e.target.checked, notificaitonSettings.isEmailNotificationEnable)}}/>
            ),
            align:"center"
        },
        {
            title: "Email",
            dataIndex: "isEmailNotificationEnable",
            key:"isEmailNotificationEnable",
            isVisible: true,
            order: 3,
            align:"center",
            render: (_,notificaitonSettings) => (
                <Checkbox 
                checked={notificaitonSettings.isEmailNotificationEnable}
                onChange={ (e)=>{updateNotificationSettings(notificaitonSettings.id,notificaitonSettings.isPushNotificationEnable, e.target.checked)}}
            />
            )
        }
    ]

    const depotSettingsColumns: ITableColumn<IDepot>[] = [
        {
            title: "Название",
            dataIndex: "name",
            key:"name",
            isVisible: true,
            order: 1,
            width: 200,
            align:"center"
        },
        {
            title: "Показывать",
            dataIndex: "isShow",
            key:"isShow",
            isVisible: true,
            order: 2,
            render: (_,depot) => (
                <Checkbox 
                checked={settings?.depotsIds?.includes(depot.id)} 
                onChange={()=>{updateDepotSettings(depot.id)}}/>
            ),
            align:"center"
        }
    ]

    const fuelTypeSettingsColumns: ITableColumn<IFuelType>[] = [
        {
            title: "Название",
            dataIndex: "name",
            key:"name",
            isVisible: true,
            order: 1,
            width: 200,
            align:"center"
        },
        {
            title: "Показывать",
            dataIndex: "isShow",
            key:"isShow",
            isVisible: true,
            order: 2,
            render: (_,fuelType) => (
                <Checkbox 
                checked={settings?.fuelTypesIds?.includes(fuelType.id)}
                onChange={()=>{updateFuelTypesSettings(fuelType.id)}} />
            ),
            align:"center"
        }
    ]
    
    useEffect( ()=> {
        updateSettings()
    },[customerContext])

    const updateDepotSettings = (id: number) => {
        let customerSettings = { ...settings }

        if (customerSettings) {
            if (customerSettings.depotsIds?.includes(id)) {
                customerSettings.depotsIds = customerSettings.depotsIds.filter(depotId => depotId !== id)
            } else {
                customerSettings.depotsIds = [...(customerSettings.depotsIds || []), id]
            }
        }

        setSettings(customerSettings)
    } 

    const updateFuelTypesSettings = (id: number) => {
        let customerSettings = { ...settings }

        if (customerSettings) {
            if (customerSettings.fuelTypesIds?.includes(id)) {
                customerSettings.fuelTypesIds = customerSettings.fuelTypesIds.filter(depotId => depotId !== id)
            } else {
                customerSettings.fuelTypesIds = [...(customerSettings.fuelTypesIds || []), id]
            }
        }
        
        setSettings(customerSettings)
    } 

    const updateNotificationSettings  = (id:number, isPushNotificationEnable: boolean, isEmailNotificationEnable: boolean ) => {
        let customerSettings = { ...settings }

        if (customerSettings.notificationSettings) {
            customerSettings.notificationSettings.find(ns=>ns.id == id)!.isPushNotificationEnable = isPushNotificationEnable
            customerSettings.notificationSettings.find(ns=>ns.id == id)!.isEmailNotificationEnable = isEmailNotificationEnable
        }
        
        setSettings(customerSettings)
    }

    const updateSettings = async () =>{
        if (customerContext.customer?.customerClientId) {
            await getSettingsByCustomerClientId(customerContext.customer.customerClientId).then(res=>{
                if (res.succeeded) {
                    setSettings(res.response)
                }
            })
        }
    }

    const onSaveSettings = async () => {
        await updateCustomerSettingsMutation.mutateAsync(settings!)
        updateSettings()
    }
    
    return (
        <div>
            <Row justify="space-between" align="middle" style={{ padding: "20px 0" }}>
                <Col>
                    <Title level={3}>Настройки</Title>
                </Col>
                <Col>
                    <Button onClick={onSaveSettings} type="primary"  className={classes["save-btn"]}>
                        Сохранить
                    </Button>
                 </Col>
            </Row>
            <Row className={classes["order-row"]} style={{ width : "70%" }}>
                <Title level={4} >НБ отображения в таблице цен/лимитов</Title>
                <Table 
                scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
                dataSource={depotsData?.response}
                columns={depotSettingsColumns}
                rowKey={"name"}
                pagination={false}/>
            </Row>
            <Row className={classes["order-row"]} style={{ width : "70%" }}>
                <Title level={4} >Выбор вида топлива отображения в таблице цен/лимитов</Title>
                <Table 
                scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
                dataSource={fuelTypeData?.response.data}
                columns={fuelTypeSettingsColumns}
                rowKey={"name"}
                pagination={false}/>
            </Row>
            <Row className={classes["order-row"]}>
                <Title level={4}>Настройки увдеолмений</Title>
                <Table 
                scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
                dataSource={settings?.notificationSettings}
                columns={notificaitonSettingsTypeColumns}
                rowKey={"status"}
                pagination={false}/>
            </Row>
        </div>
    )
}

export default CustomerSettingsPage