import { useQuery } from "@tanstack/react-query"

import { FUEL_LIMITS_QUERY_KEY, FUEL_LIMITS_SETTINGS_QUERY_KEY } from "@/constants/query"
import { getFuelLimitById, getFuelLimitCardById, getFuelLimits } from "@/http/fuelLimit"

import { IRequestParams } from "@/types/api"

export const useFuelLimitsQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getFuelLimits(params || {}),
    queryKey: [FUEL_LIMITS_QUERY_KEY, params]
  })
}

export const useFuelLimitByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getFuelLimitById(id),
    queryKey: [FUEL_LIMITS_QUERY_KEY, id],
    enabled: id !== 0
  })
}

export const useFuelLimitCardById = (id: number) => {
  return useQuery({
    queryFn: () => getFuelLimitCardById(id),
    queryKey: [FUEL_LIMITS_SETTINGS_QUERY_KEY, id],
    enabled: id !== 0
  })
}
