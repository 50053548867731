export enum Resource {
  Account = "account",
  CustomerOrderHistory = "order-history",

  ManagerAccount = "manager-account",

  Driver = "drivers",
  FuelType = "fuel-types",
  Transport = "transports",
  Countries = "countries",

  Customer = "customers",
  CustomerClient = "customer-clients",
  CustomerCategory = "customer-categories",

  Depot = "depots",
  Fuel = "fuels",
  FuelQuote = "fuel-quotes",
  FuelCustomer = "fuel-customers-price",
  FuelCustomerCategory = "fuel-customer-categories-price",
  FuelLimit = "fuel-limits",
  FuelArchive = "fuel-archive",
  Order = "orders",
  DeliveryAddress = "delivery-address",

  OrderReport = "order-reports",

  User = "users",
  Role = "roles",

  Finance = "finance",
  CustomerFinance = "customer-finance",

  CustomerByClient = "customers-by-client",
  UserByCustomer = "users-by-customer",

  DispatcherOrders = "dispatcher-orders",
  DispatcherTimeCoordinate = "time-coordinate",
  Authorities = "authorities", // внутренние доверенности

  Notification = "notifications",
  NotificationSettings = "notification-settings",
  Analytics = "analytics",
  ManagerAnalytics = "manager-analytics",

  // Общая аналитика между ролями
  PricesAnalytics = "analytics-prices",

  // Аналитика для клиента (Refusal пока под вопросом)
  OrdersAnalytics = "analytics-orders",
  RefusalsAnalytics = "analytics-refusals",
  OffersAnalytics = "analytics-offers",

  // Аналитика менеджера и админа
  SalesAnalytics = "analytics-sales",
  LimitsAnalytics = "analytics-limits",
  LimitsSalesAnalytics = "analytics-limits-sales",
  DeliveriesPickupsAnalytics = "analytics-deliveries-pickups",

  CustomerSettings = "customer-settings",

  TimeUnloadFuels = "time-unload-fuels"
}

export enum Scope {
  Create = "create",
  Read = "read",
  PartialRead = "partial-read",
  Update = "update",
  Delete = "delete",
  Actions = "actions",
  ReadAll = "read-all",
  PartialUpdate = "partial-update",
  AllActions = "all-actions",
  Menu = "menu"
}
