import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { IFuelQuote, IFuelQuoteCreate, IFuelQuoteUpdate, IFuelQuoteArchive, IFuelQuoteCard, IFuelQuoteCategorySettingRequest } from "@/types/IFuelQuote"
import { ITableColumn } from "@/types/ui"

export interface IFuelForOrderParam {
  Date: Date
  FuelId: number
  CustomerId: number
}

export interface IFuelsByDepotParam {
  DepotId: number
  Date: Date
}

export interface IFuelRecordsByDepotParam {
  DepotId: number
  CustomerId: number
}

export const getFuelQuotesByFuel = (fuelId: number,
  params: IRequestParams
): Promise<IPagingResponse<IFuelQuote>> =>
  client.get(`/FuelQuotes/fuel/${fuelId}`, {
    params
  })

export const getFuelQuoteById = (id: number): Promise<IResponse<IFuelQuote>> =>
    client.get(`/FuelQuotes/${id}`)

export const getFuelQuoteCardById = (id: number): Promise<IResponse<IFuelQuoteCard>> =>
  client.get(`/FuelQuotes/card/${id}`)

export const getFuelQuotesArchive = (params: IRequestParams): Promise<IPagingResponse<IFuelQuoteArchive>> =>
    client.get("/FuelQuotes/archive", {
      params
    })

export const createFuelQuote = (
    body: IFuelQuoteCreate
): Promise<IResponse<number>> => client.post("/FuelQuotes", body)

export const updateFuelQuote = (
    body: IFuelQuoteUpdate
): Promise<IResponse<string>> => client.put("/FuelQuotes", body)

export const getFuelQuotesExcel = (params: IRequestParams, fuelId: number, columns?: ITableColumn<IFuelQuote>[]):
  Promise<IResponse<IDownloadDocument>> =>
  client.post(`/FuelQuotes/Excel/${fuelId}`,
    columns, {
    params
  })

export const getFuelQuotesArchiveExcel = (params: IRequestParams, columns?: ITableColumn<IFuelQuoteArchive>[]):
  Promise<IResponse<IDownloadDocument>> =>
  client.post("/FuelQuotes/archive/Excel",columns, {
    params
  })

export const deleteFuelQuotes = (ids: number[]): Promise<IResponse<boolean>> =>
    client.delete("/FuelQuotes", {
        data: {
            ids
        }
    })

export const editFuelQuoteSettingsCategory = (
  body: IFuelQuoteCategorySettingRequest[]
): Promise<IResponse<boolean>> => client.post("/FuelQuotes/categorySettings", { settings : body })

