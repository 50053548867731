import { useQuery } from "@tanstack/react-query"

import {
  TRANSPORT_QUERY_KEY,
  TRANSPORT_FOR_ORDER_QUERY_KEY
} from "@/constants/query"
import {
  getTransport,
  getTransportById,
  getTransportForOrder
} from "@/http/transport"

import { IRequestParams } from "@/types/api"

export const useTransportQuery = (params?: IRequestParams, customerId?: number) => {
  return useQuery({
    queryFn: () => getTransport(params || {}, customerId),
    queryKey: [TRANSPORT_QUERY_KEY, params, customerId],
    retry: false
  })
}

export const useTransportForOrderQuery = (orderId: number, customerId?: number) => {
  return useQuery({
    queryFn: () => getTransportForOrder(orderId, customerId),
    queryKey: [TRANSPORT_FOR_ORDER_QUERY_KEY, orderId, customerId],
    retry: false
  })
}

export const useTransportByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getTransportById(id),
    queryKey: [TRANSPORT_QUERY_KEY, id],
    enabled: id !== 0
  })
}
