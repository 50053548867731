import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/_Shared/FilterWrapper"

import { useFilterStorage } from "@/hook/useFilterStorage"

import { FUEL_LIMITS_KEY } from "@/types/IFuelLimit"
import { FilterField, FilterFieldType } from "@/types/ui"

export const useFuelLimitsFilters = () => {
  const [filters, setFilters] = useState<FilterField[]>([])

  const { getFilter } = useFilterStorage({
    key: FUEL_LIMITS_KEY
  })

  const oldFilters: FieldData[] = getFilter()

  useEffect(() => {
    setFilters(FuelLimitsFilters)
  })

  return {
    filters: filters.map((f) => {
      const currentFilter = oldFilters.find(
        (i) => (i.name as string[])[0] === f.name
      )

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      switch (f.type) {
        case FilterFieldType.Date:
          return {
            ...f, value: dayjs(new Date(currentFilter.value as string))
          }
        case FilterFieldType.DateRange:
          return {
            ...f, value: [
              dayjs(new Date(currentFilter.value[0] as string)),
              dayjs(new Date(currentFilter.value[1] as string))
            ]
          }
        default:
          return {
            ...f, value: currentFilter.value
          }
      }
    })
  }
}

const FuelLimitsFilters: FilterField[] = [
  {
    text: "Начало периода",
    name: "startDate",
    type: FilterFieldType.DateRange
  },
  {
    text: "Конец периода",
    name: "endDate",
    type: FilterFieldType.DateRange
  },
  {
    text: "Лимит",
    name: "limit",
    type: FilterFieldType.Number
  }
]
