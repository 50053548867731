import { FC } from "react"

const AnalyticsOffersPage:FC = ()=>{
    return (
        <div>
            <h2>Динамика предложений</h2>
        </div>
    )
}

export default AnalyticsOffersPage