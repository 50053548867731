import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData, numberOperatorName } from "@/components/_Shared/FilterWrapper"

import { useFilterStorage } from "@/hook/useFilterStorage"

import { CUSTOMERS_KEY } from "@/types/ICustomer"
import { FilterField, FilterFieldOperator, FilterFieldType } from "@/types/ui"


export const useCustomerFilters = () => {
  const [filters, setFilters] = useState<FilterField[]>([])

  const { getFilter } = useFilterStorage({
    key: CUSTOMERS_KEY
  })

  const oldFilters: FieldData[] = getFilter()

  useEffect(() => {
    setFilters([
      ...CustomerFilters
    ])
  }, [])

  return {
    filters: filters.map(f => {
      const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      return {
        ...f, value: f.type === FilterFieldType.Date
          ? dayjs(new Date(currentFilter.value as string))
          : currentFilter.value,
          operator: f.type === FilterFieldType.Number ?
           oldFilters.find(i => (i.name as string[])[0] === f.name + numberOperatorName)?.value 
           : f.operator
      }
    })
  }
}

const CustomerFilters: FilterField[] = [
  {
    text: "Наименование",
    name: "name",
    type: FilterFieldType.Text,
    operator: FilterFieldOperator.Contains
  },
  {
    text: "Баланс",
    name: "balance",
    type: FilterFieldType.Number
  },
  {
    text: "Сумма заказов в работе",
    name: "sumOrder",
    type: FilterFieldType.Number
  },
  {
    text: "Сумма доступная для заказа",
    name: "availableSum",
    type: FilterFieldType.Number
  },
  {
    text: "Дневной лимит",
    name: "dayLimit",
    type: FilterFieldType.Number
  }
]
