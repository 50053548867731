import { FC, Key, useRef } from "react"

import { Button } from "antd"
import { useNavigate, useSearchParams } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"
import { CustomerAddForm, CustomerAddFormProps } from "@/components/Customers/CustomerAddForm"
import {
  OrderAddForm,
  OrderAddFormProps
} from "@/components/Order/OrderAddForm"
import { Resource, Scope } from "@/constants/permission"
import { getCustomerExcel } from "@/http/customer"
import { customerColumns } from "@/pages/Customer/CustomerColumns"

import { useCustomerFilters } from "./CustomerFilters"

import { useCustomerMutation } from "@/hook/Dictionaries/Customers/useCustomerMutation"
import { useCustomerListQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { usePermission } from "@/hook/usePermission"
import { useTable } from "@/hook/useTable"

import { CUSTOMERS_KEY, ICustomer } from "@/types/ICustomer"

import { paths } from "@/router/paths"

import { getInitialColumns } from "@/utils/getInitialColumns"

const CustomerPage: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const customerComponent = useRef<CustomerAddFormProps>(null)
  const orderComponent = useRef<OrderAddFormProps>(null)

  const { download: excelDownload, isLoading: isExcelLoading } =
    useDownloadFile(() =>
      getCustomerExcel(
        {
          Filter: filter.filter,
          OrderBy: order.orderBy
        },
        getInitialColumns(customerColumns, CUSTOMERS_KEY)
      )
    )

  const { deleteMutation } = useCustomerMutation()

  const { hasPermission } = usePermission()
  
  const addOrderButtonCol = [{
    title: "",
    dataIndex: "addOrder",
    key: "addOrder",
    render: (_: any, customer: ICustomer) => <Button type="primary" onClick={() => {orderComponent.current?.open(customer)}}>Сделать заказ</Button>,
    isVisible: true,
    order: 7
  }]

  let customerCols = [
    ...customerColumns
  ]

  if(hasPermission(Resource.Order, Scope.Create)) {
    customerCols = [
      ...customerCols,
      ...addOrderButtonCol
    ]
  }

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(customerCols, CUSTOMERS_KEY),
    key: CUSTOMERS_KEY
  })

  const { data, isLoading } = useCustomerListQuery({
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })

  const { filters } = useCustomerFilters()

  const onAddHandler = () => {
    customerComponent.current?.open()
  }

  const onEditHandler = (id: number) => {
    customerComponent.current?.open(id)
  }

  const onRemoveHandler = async (ids: Key[]) => {
    await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onOpenForEdit = () => {
    const searchId = searchParams.get("edit")
    if (searchId) {
      onEditHandler(Number(searchId))
    }
  }

  useLocationSearchHandler({
    value: "edit",
    handler: onOpenForEdit
  })

  useLocationSearchHandler({
    value: "openForm",
    handler: onAddHandler
  })

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  const onRowClickHandler = (data: ICustomer) => {
    navigate(paths.customerById(data.id))
  }

  return (
    <>
      <OrderAddForm ref={orderComponent} />
      <CustomerAddForm ref={customerComponent} />
      <Table
        dataSource={data?.response.data}
        columns={columns.data}
        rowKey="id"
        isLoading={isLoading}
        onUpdateColumns={columns.onColumnChange}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: CUSTOMERS_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        addText="Добавить"
        onSorterChange={order.setOrderBy}
        onRowClick={onRowClickHandler}
        permission={{
          resource: Resource.Customer
        }}
      />
    </>
  )
}

export default CustomerPage
