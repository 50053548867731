import { Key, useEffect, useState } from "react"

import { ArrowsAltOutlined, EyeInvisibleOutlined } from "@ant-design/icons"
import { Modal, Tabs, TabsProps } from "antd"

import { FieldOrder } from "@/components/_Shared/Table/FieldOrder"
import { FieldVisibility } from "@/components/_Shared/Table/FieldVisibility"


import { ITableColumn } from "@/types/ui"


interface ColumnsSettingProps<T> {
  open: boolean
  onCancel: () => void
  onSubmit: (updatedColumns: ITableColumn<T>[]) => void
  columns: ITableColumn<T>[]
}

export const ColumnsSetting = <T extends object>(props: ColumnsSettingProps<T>) => {
  const { open, columns, onSubmit, onCancel } = props

  const [updatedColumns, setUpdatedColumns] = useState<ITableColumn<T>[]>(columns)

  useEffect(() => {
    setUpdatedColumns(columns)
  }, [columns])

  const toggleColumnVisible = (key: Key) => {
    setUpdatedColumns((prevState) => prevState.map((item) => (item.key === key ? { ...item, isVisible: !item.isVisible } : item)))
  }


  const submitHandler = () => {
    onSubmit(updatedColumns)
  }

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Видимость столбцов",
      children: <FieldVisibility columns={updatedColumns} toggleColumnVisible={toggleColumnVisible} />,
      icon: <EyeInvisibleOutlined />
    },
    {
      key: "2",
      label: "Порядок столбцов",
      children: <FieldOrder columns={updatedColumns} onUpdateColumns={setUpdatedColumns} />,
      icon: <ArrowsAltOutlined />
    }
  ]

  return (
    <Modal
      open={open}
      centered={true}
      onCancel={onCancel}
      title="Настроики столбцов"
      okText="Сохранить"
      cancelText="Отмена"
      onOk={submitHandler}
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Modal>
  )
}
