import { FC, Key, useRef } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"
import { CustomerClientAddForm, CustomerClientAddFormProps } from "@/components/CustomerClients/CustomerClientAddForm"
import { Resource } from "@/constants/permission"
import { getCustomerClientExcel } from "@/http/customerClient"
import { customerClientColumns } from "@/pages/CustomerClient/CustomerClientColumns"

import { useCustomerClientFilters } from "./CustomerClientFilters"

import { useCustomerClientMutation } from "@/hook/CustomerClients/useCustomerClientMutation"
import { useCustomerClientsQuery } from "@/hook/CustomerClients/useCustomerClientQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { useTable } from "@/hook/useTable"

import { CUSTOMER_CLIENTS_KEY, ICustomerClient } from "@/types/ICustomerClient"

import { paths } from "@/router/paths"

import { getInitialColumns } from "@/utils/getInitialColumns"

const CustomerClientPage: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const customerClientComponent = useRef<CustomerClientAddFormProps>(null)

  const { download: excelDownload, isLoading: isExcelLoading } =
    useDownloadFile(() =>
      getCustomerClientExcel(
        {
          Filter: filter.filter,
          OrderBy: order.orderBy
        },
        getInitialColumns(customerClientColumns, CUSTOMER_CLIENTS_KEY)
      )
    )

  const { deleteMutation } = useCustomerClientMutation()

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(customerClientColumns, CUSTOMER_CLIENTS_KEY),
    key: CUSTOMER_CLIENTS_KEY
  })

  const { data, isLoading } = useCustomerClientsQuery({
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })

  const { filters } = useCustomerClientFilters()

  const onAddHandler = () => {
    customerClientComponent.current?.open()
  }

  const onEditHandler = (id: number) => {
    customerClientComponent.current?.open(id)
  }

  const onRemoveHandler = async (ids: Key[]) => {
    await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onOpenForEdit = () => {
    const searchId = searchParams.get("edit")
    if (searchId) {
      onEditHandler(Number(searchId))
    }
  }

  useLocationSearchHandler({
    value: "edit",
    handler: onOpenForEdit
  })

  useLocationSearchHandler({
    value: "openForm",
    handler: onAddHandler
  })

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  const onRowClickHandler = (data: ICustomerClient) => {
    navigate(paths.customerClientById(data.id))
  }

  return (
    <>
      <CustomerClientAddForm ref={customerClientComponent} />
      <Table
        dataSource={data?.response.data}
        columns={columns.data}
        rowKey="id"
        isLoading={isLoading}
        onUpdateColumns={columns.onColumnChange}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: CUSTOMER_CLIENTS_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        addText="Добавить"
        onSorterChange={order.setOrderBy}
        onRowClick={onRowClickHandler}
        permission={{
          resource: Resource.CustomerClient
        }}
      />
    </>
  )
}

export default CustomerClientPage
