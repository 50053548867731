import { FC } from "react"

import { Button, Space } from "antd"
import { useNavigate } from "react-router-dom"

import classes from "./LogoSidebar.module.scss"


interface ILogoSidebarProps {
    shown: boolean
}

export const LogoSidebar: FC<ILogoSidebarProps> = (props) => {
    const appName = process.env.REACT_APP_APP_NAME
    const { shown } = props

    let navigate = useNavigate()
    const routeChange = () => {
        navigate("/")
    }

    return (
        <Button type="text" className={classes.logo + " " + (shown ? classes.logoShown : classes.logoHidden)} onClick={routeChange}>
            <Space align="center">
                <img alt="logo" src="nordLogo.png" height={24} />
            </Space>
        </Button>
    )
}