import { ICustomerClient } from "./ICustomerClient"

export interface IDocumentFile {
	content: string
	name: string
  }

export interface ITransport {
	id: number
	model: string
	modelId: number
	type: string
	typeId: number
	class: ITransportClass
	className: string
	number: string
	region?: string
	brand?: string
	color?: string
	vin?:  string
	owner?: string
	weightWithoutLoad?: number
	permittedMaximumMass?: number
	axlesNumber?: number
	liter?: number
	calibrationCertificateNumber?: string
	verificationCertificateNumber?: string
	dateExpirationCertificate: string
	dateBeginningCertificate: string
	section1?: number
	section2?: number
	section3?: number
	section4?: number
	section5?: number
	section6?: number
	section7?: number
	section8?: number
	approved?: boolean
	verificationStatus: number
	verificationStatusText: string
	clients: ICustomerClient[]
	stsScans?: IDocumentFile[]
}

export enum VerificationStatus
{
    Awaits,
    Rejected,
    Confirmed
}

export enum ITransportClass {
  Truck,
  Pickup,
	TankerTruck
}

export interface ITransportType {
  id: number
  name: string
  class: ITransportClass
  className: string
}

export const TransportClassForSelect = [
  { id: ITransportClass.Truck, value: "Тягач" },
  { id: ITransportClass.Pickup, value: "Прицеп" },
  { id: ITransportClass.TankerTruck, value: "Автоцистерна" }
]

export interface ITransportCreate {
	modelId: number
	typeId: number
	modelName: string
	typeName: string
	class: ITransportClass
	number: string
	region: string
	brand: string
	color: string
	vin:  string
	owner: string
	weightWithoutLoad: number
	permittedMaximumMass: number
	axlesNumber: number
	liter: number
	calibrationCertificateNumber: string
	verificationCertificateNumber: string
	dateExpirationCertificate: string
	dateBeginningCertificate: string
	section1: number
	section2: number
	section3: number
	section4: number
	section5: number
	section6: number
	section7: number
	section8: number
    approved: boolean
    clientIds: number[]
	stsScans?: IDocumentFile[]
}

export interface ITransportUpdate extends ITransportCreate {
  id: number
}

export interface ITransportChangeVerificationStatus {
	id : number
	status : VerificationStatus
  }

export const TRANSPORT_KEY = "TRANSPORT_KEY"
