import { FC, useEffect, useRef, useState } from "react"

import { EditOutlined } from "@ant-design/icons"
import { Button, Card, Empty, Skeleton, Space, Tabs, TabsProps, Tooltip } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "@/_styles/cardById.module.scss"
import { CategoryFuelSettingsTable } from "@/components/CustomerCategories/CatgoryFuelSettingsTable"
import { CustomerCategoryAddForm, CustomerCategoryAddFormProps } from "@/components/CustomerCategories/CustomerCategoryAddForm"
import { Resource, Scope } from "@/constants/permission"

import { useCustomerCategoryByIdForCardQuery } from "@/hook/Dictionaries/Customers/useCustomerCategoryQuery"
import { usePermission } from "@/hook/usePermission"


import { paths } from "@/router/paths"

const CustomerCategoryById: FC = () => {
  const navigate = useNavigate()
  const { hasPermission } = usePermission()
  
  const { id } = useParams<{ id?: string }>()

  const [tabItems, setTabItems] = useState<TabsProps["items"]>([])
  const [categoryPercent, setCategoryPercent] = useState<number>(0)

  const customerCategoryComponent = useRef<CustomerCategoryAddFormProps>(null)

  const { data: category, isLoading, isError, refetch } = useCustomerCategoryByIdForCardQuery(Number(id))

  const onBackHandler = () => {
    navigate(paths.customerCategories)
  }

  const refetchTabs = () => {
    refetch()
  }

  useEffect(() => {
    if (!isLoading && category && category.response.fuelSettings?.length > 0) {
      setCategoryPercent(category.response.percent)

      const fuelSettings = category.response.fuelSettings
      const onlyDepots = fuelSettings.map(item => item.depotName).filter((value, index, self) => self.indexOf(value) === index)

      const tabs: TabsProps["items"] = []
      if(onlyDepots.length > 0) {
      for(let i = 0; i<onlyDepots.length ; i++) {
        tabs.push({
          key: `${i}`,
          label: onlyDepots[i],
          children: <CategoryFuelSettingsTable 
            categoryId={Number(id)}
            settings={fuelSettings.filter(f=> f.depotName === onlyDepots[i])}
            refetchCallback={refetchTabs}
          />
        })
      }
      }
      setTabItems(tabs)
    }
    // eslint-disable-next-line
  }, [id, category])

  const onEditHandler = () => {
    customerCategoryComponent.current?.open(category?.response.id)
  }

  const onCloseEditFormHandler = () => {
    refetch()
  }

  if (isError) {
    return <Empty description="Категория не найдена" />
  }

  return (
    <>
    <CustomerCategoryAddForm ref={customerCategoryComponent} handleClose={onCloseEditFormHandler} />
    <Skeleton loading={isLoading}>
      {category && (
        <>
          <Card title={category.response.name} bordered >
              <Space style={{ paddingBottom: "15px" }}>
                Процент объема: <b>{categoryPercent}%</b>
                {hasPermission(Resource.CustomerCategory, Scope.Update) && <Tooltip title="Редактировать">
                  <Button type="dashed" icon={<EditOutlined />} onClick={onEditHandler} />
                </Tooltip>
                }
              </Space>
            <Tabs
              type="card"
              items={tabItems}
            />
          </Card>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
    </>
  )
}

export default CustomerCategoryById
