import { useQuery } from "@tanstack/react-query"

import { DELIVERY_ADDRESS_QUERY_KEY, DELIVERY_ADDRESS_FOR_ORDER_QUERY_KEY } from "@/constants/query"
import { getAddress, getAddressById, getAddressForOrder, getAllAddress } from "@/http/deliveryAddress"

import { IRequestParams } from "@/types/api"

export const useDeliveryAddressQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getAddress(params || {}),
    queryKey: [DELIVERY_ADDRESS_QUERY_KEY, params],
    retry: false
  })
}

export const useAllDeliveryAddressQuery = (params?: IRequestParams) => {
  const query =  useQuery({
    queryFn: () => getAllAddress(params || {}),
    queryKey: [DELIVERY_ADDRESS_QUERY_KEY, params],
    retry: false
  })
  return{
    ...query,
    refetch: query.refetch
  }
}

export const useDeliveryAddressForOrderQuery = (customerId: number) => {
  return useQuery({
    queryFn: () => getAddressForOrder(customerId),
    queryKey: [DELIVERY_ADDRESS_FOR_ORDER_QUERY_KEY, customerId],
    retry: false
  })
}

export const useDeliveryAddressByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getAddressById(id),
    queryKey: [DELIVERY_ADDRESS_QUERY_KEY, id],
    enabled: id !== 0
  })
}
