import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import {
  IOrderCreate,
  IOrder,
  IOrderUpdate,
  IOrderChangeStatus,
  IOrderPayForOrder,
  IOrderApprove,
  IOrderCoordinateTime,
  IOrderLog,
  IOrderRecreate
} from "@/types/IOrder"
import { ITableColumn } from "@/types/ui"

export const getOrder = (
  params: IRequestParams
): Promise<IPagingResponse<IOrder>> =>
  client.get("/Orders", {
    params
  })

export const getOrdersByCustomer = (
  params: IRequestParams,
  id: number
): Promise<IPagingResponse<IOrder>> =>
  client.get(`/Orders/customer/${id}`, {
    params
  })

export const getOrderById = (id: number): Promise<IResponse<IOrder>> =>
  client.get(`/Orders/${id}`)

export const getOrdersExcel = (params: IRequestParams, columns?: ITableColumn<IOrder>[]): Promise<IResponse<IDownloadDocument>> => client.post("/Orders/Excel",
    columns, {
        params
    })

export const getOrdersByCustomerExcel = (params: IRequestParams, id: number, columns?: ITableColumn<IOrder>[]): Promise<IResponse<IDownloadDocument>> => client.post(
    `/Orders/customer/${id}/Excel`,
    columns, {
        params
    })

export const GetOrderLogsById = (id: number) : Promise<IResponse<IOrderLog[]>> =>
  client.get(`/Orders/${id}/Logs`)

export const createOrder = (body: IOrderCreate): Promise<IResponse<number>> => client.post("/Orders", body)

export const recreateOrder = (body: IOrderRecreate) : Promise<IResponse<number>> => client.post("/Orders/recreate", body)

export const updateOrder = (body: IOrderUpdate): Promise<IResponse<string>> =>
  client.put("/Orders", body)

export const updateOrderByCustomer = (body: IOrderUpdate): Promise<IResponse<string>> =>
  client.put("/Orders/byCustomer", body)

export const updateTransportOrder = (body: IOrderUpdate) : Promise<IResponse<string>> =>
  client.put("/Orders/transport", body)

export const deleteOrders = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Orders", {
    data: {
      ids
    }
  })

export const approveOrder = (
  body: IOrderApprove
): Promise<IResponse<boolean>> => client.put("/Orders/approve", body)

export const payForOrder = (
  body: IOrderPayForOrder
): Promise<IResponse<boolean>> => client.put("/Orders/pay", body)

export const coordinateOrderTime = (
  body: IOrderCoordinateTime
): Promise<IResponse<boolean>> => client.put("/Orders/coordinate-time", body)

export const changeStatusOrder = (
  body: IOrderChangeStatus
): Promise<IResponse<boolean>> => client.put("/Orders/change-status", body)

export const processStatusOrder = (id: number): Promise<IResponse<boolean>> =>
  client.put("/Orders/process-status", { id: id })
