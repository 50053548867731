import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import {
  ICategoryFuelSettingRequest,
  ICustomerCategory,
  ICustomerCategoryCard,
  ICustomerCategoryCreate,
  ICustomerCategoryUpdate
} from "@/types/ICustomerCategory"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ISelectListItem, ITableColumn } from "@/types/ui"

export interface ICustomerCategoryParam {
  id: number
}

export const getCustomerCategory = (
  params: IRequestParams
): Promise<IPagingResponse<ICustomerCategory>> =>
  client.get("/CustomerCategories", {
    params
  })

export const getCustomerCategories = (): Promise<
  IPagingResponse<ICustomerCategory>
> => client.get("/CustomerCategories")

export const getCustomerCategorySelectList = (): Promise<IResponse<ISelectListItem[]>> => client.get("/CustomerCategories/selectList")

export const getCustomerCategoryById = (
  id: number
): Promise<IResponse<ICustomerCategory>> =>
  client.get(`/CustomerCategories/${id}`)

  export const getCustomerCategoryByIdForCard = (
    id: number
  ): Promise<IResponse<ICustomerCategoryCard>> =>
    client.get(`/CustomerCategories/card/${id}`)

  export const getCustomerCategoryPercentSum = (): Promise<IResponse<number>> =>
    client.get("/CustomerCategories/sum")

  export const getCustomerCategoryExcel = (
  params: IRequestParams,
  columns?: ITableColumn<ICustomerCategory>[]
): Promise<IResponse<IDownloadDocument>> =>
  client.post("/CustomerCategories/Excel", columns, {
    params
  })

export const createCustomerCategory = (
  body: ICustomerCategoryCreate
): Promise<IResponse<number>> => client.post("/CustomerCategories", body)

export const updateCustomerCategory = (
  body: ICustomerCategoryUpdate
): Promise<IResponse<string>> => client.put("/CustomerCategories", body)

export const deleteCustomerCategories = (
  ids: number[]
): Promise<IResponse<boolean>> =>
  client.delete("/CustomerCategories", {
    data: {
      ids
    }
  })

  export const editFuelSettingsCategory = (
    body: ICategoryFuelSettingRequest
  ): Promise<IResponse<boolean>> => client.post("/CustomerCategories/fuelSettings", body)
