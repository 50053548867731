import { FC, useEffect, useState } from "react"

import { FileExcelOutlined } from "@ant-design/icons"
import { Button, Form, Row, Tooltip as AntdTooltip, Typography } from "antd"

import { AnalyticsBarChart } from "@/components/Analytics/AnalyticsBarChart"
import { AnalyticsLineChart } from "@/components/Analytics/AnalyticsLineChart"
import {
  useCustomerAnalyticsHeader
} from "@/components/Analytics/AnalyticsSettings/CustomerAnalytics/useCustomerAnalyticsHeader"
import { getAnalyticsPriceExcel } from "@/http/analytics"

import { usePricesAnalyticsQuery } from "@/hook/Analytics/useAnalyticsQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"

import { IAnalyticsPageDisplayUnit } from "@/types/IAnalytics"
import { IDictionary } from "@/types/ui"

import { formatNumber } from "@/utils/formatNumber"

const { Title } = Typography

const AnalyticsPricePage: FC = () => {
  const [form] = Form.useForm()
  const { optionsList, component: HeaderComponent, displayUnit, isComparison } = useCustomerAnalyticsHeader({
    baseDisplayUnits: [],
    additionalDisplayUnits: [{
      label: "Цена",
      value: "price",
      bigLabel: "Цена"
    }]
  })

  const { data: analyticsPriceData } = usePricesAnalyticsQuery({ optionsList })

  const [analyticsMapped, setAnalyticsMapped] = useState<IDictionary<number | string>[]>()
  const [uniqueFuels, setUniqueFuels] = useState<IAnalyticsPageDisplayUnit[]>([])

  const { download: excelDownload } = useDownloadFile(() => getAnalyticsPriceExcel({
      optionsList
    })
  )

  useEffect(() => {
    const data = analyticsPriceData?.response.data

    if (!data)
      return

    const fuelsSet = new Set<string>()

    setAnalyticsMapped(data.map(a => {
      const newDictionary: IDictionary<string | number> = {}
      newDictionary["period"] = a.period
      a.fuels?.forEach(f => {
        fuelsSet.add(f.fuelTypeName)
        newDictionary[f.fuelTypeName] = parseFloat(formatNumber(f.price))
      })
      return newDictionary
    }))

    setUniqueFuels(Array.from(fuelsSet.values()).sort().map(f => ({
      value: f,
      label: "",
      bigLabel: `${displayUnit.bigLabel} (${f})`
    })))
  }, [displayUnit, analyticsPriceData])

  return (
    <>
      <Form form={form}>
        <Row>
          <Title style={{ marginLeft: "12px" }} level={3}>Динамика цен</Title>
        </Row>
        {
          HeaderComponent
        }
        <AntdTooltip title="Выгрузить в Excel" style={{ marginLeft: "auto" }}>
          <Button
            type="primary"
            disabled={false}
            onClick={excelDownload}
            icon={<FileExcelOutlined/>}
          />
        </AntdTooltip>
        <Row style={{ marginTop: "12px" }}>
          {
            !isComparison &&
              <AnalyticsLineChart data={analyticsMapped} dataKeys={uniqueFuels}/>
          }
          {
            isComparison &&
              <AnalyticsBarChart data={analyticsMapped} dataKeys={uniqueFuels}/>
          }
        </Row>
      </Form>
    </>
  )
}

export default AnalyticsPricePage
