import dayjs, { Dayjs } from "dayjs"
import moment from "moment"

export const formatDate = (date: string | Date, format: string = "DD.MM.yyyy") => {
  return formatDateTime(date.toString(), format)
}

export const formatTime = (date: string | Date, format: string = "HH:mm") => {
  return formatDateTime(date.toString(), format)
}

export const formatDateTime = (date: string, format: string) => {
  return moment(new Date(date)).format(format)
}

export const serializeRangeDayjs = (range: Dayjs[]): string => {
  return `${range[0]}||${range[1]}`
}

export const deserializeRangeDayjs = (value: string): Dayjs[] => {
  return [
    ...value.split("||").map((date) => dayjs(date))
  ]
}
