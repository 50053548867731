import { getTransformedFuelDensity } from "@/types/IFuel"
import { IFuelQuoteArchive } from "@/types/IFuelQuote"
import { ITableColumn } from "@/types/ui"

import { formatDate } from "@/utils/formatDate"
import { getMoney } from "@/utils/formatNumber"

export const fuelArchiveColumns: ITableColumn<IFuelQuoteArchive>[] = [
  {
    title: "ID",
    key: "id",
    dataIndex: ["id"],
    width: 80,
    sorter: true,
    isVisible: false,
    order: 1
  },
  {
    title: "Нефтебаза",
    key: "depotId",
    dataIndex: ["fuel", "depot", "name"],
    width: 160,
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Тип топлива",
    key: "fuelTypeId",
    dataIndex: ["fuel", "fuelType", "name"],
    width: 140,
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Дата",
    key: "date",
    dataIndex: ["date"],
    render: (_, { date }) => <>{date && <span>{formatDate(date.toString())}</span>}</>,
    width: 140,
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Плотность",
    key: "density",
    dataIndex: ["density"],
    width: 150,
    sorter: true,
    isVisible: true,
    order: 3,
    render: (_: any, fuel: IFuelQuoteArchive) => getTransformedFuelDensity(fuel.density)
  },
  {
    title: "Цена реализации",
    key: "sellingPrice",
    dataIndex: ["sellingPrice"],
    render: (_, { sellingPrice }) => <><span>{getMoney(sellingPrice)}</span></>,
    width: 160,
    sorter: true,
    isVisible: true,
    order: 4
  },
  {
    title: "Цена закупки",
    key: "purchasePrice",
    dataIndex: ["purchasePrice"],
    render: (_, { purchasePrice }) => <><span>{getMoney(purchasePrice)}</span></>,
    width: 160,
    sorter: true,
    isVisible: true,
    order: 5
  },
  {
    title: "Дневной лимит",
    key: "dayLimit",
    dataIndex: ["dayLimit"],
    width: 120,
    sorter: true,
    isVisible: true,
    order: 6
  },
  {
    title: "В заказе",
    key: "quantityOrdered",
    dataIndex: ["quantityOrdered"],
    width: 120,
    sorter: true,
    isVisible: true,
    order: 7
  }
]
