import { useMutation, useQueryClient } from "@tanstack/react-query"

import { NOTIFICATION_QUERY_KEY } from "@/constants/query"
import { sendInformationNotifications, updateReadStatusForNotifications } from "@/http/notificaiton"

import { useMessage } from "@/hook/useMessage"

export const useNotificationMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(NOTIFICATION_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([NOTIFICATION_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const updateReadStatusForNotificationsMutation = useMutation({
    mutationFn: updateReadStatusForNotifications,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const sendInformationNotificationsMutation = useMutation({
    mutationFn: sendInformationNotifications,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {  updateReadStatusForNotificationsMutation, sendInformationNotificationsMutation }
}
