import {
  forwardRef,
  useImperativeHandle,
  useState
} from "react"

import { Modal, Typography } from "antd"

const { Text } = Typography

export interface ISimpleAction {
  text: string
  action: () => void
}

export interface ISimpleModalOption {
  title: string
  text: string
  ok: ISimpleAction
  cancel: ISimpleAction
}

export interface SimpleModalRef {
  open: (option: ISimpleModalOption) => void
  close: () => void
}

export interface SimpleModalProps {

}

export const SimpleModal = forwardRef<SimpleModalRef, SimpleModalProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [option, setOption] = useState<ISimpleModalOption>()

    useImperativeHandle(ref, () => ({
      open(option: ISimpleModalOption) {
        setIsOpen(true)
        setOption(option)
      },
      close() {
        setIsOpen(false)
      }
    }))

    return (
      <>
        <Modal
          open={isOpen}
          title={option?.title}
          okText={option?.ok.text}
          onOk={option?.ok.action}
          cancelText={option?.cancel.text}
          onCancel={option?.cancel.action}
        >
          <Text>{option?.text}</Text>
        </Modal>
      </>
    )
  }
)
