import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ICountry } from "@/types/ICountry"
import { ISelectListItem } from "@/types/ui"


export const getCountries = (params: IRequestParams): Promise<IPagingResponse<ICountry>> =>
  client.get("/Countries", {
    params
  })

export const getCountryById = (id: number): Promise<IResponse<ICountry>> => client.get(`/Countries/${id}`)

export const getCountriesSelectList = (): Promise<IResponse<ISelectListItem[]>> => client.get("/Countries/selectList")
