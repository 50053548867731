import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import {
  IAuthorities,
  IAuthoritiesCreate,
  IAuthoritiesUpdate
} from "@/types/IAuthorities"

export const getAuthorities = (
    params: IRequestParams
): Promise<IPagingResponse<IAuthorities>> =>
   client.get("/Authorities", {
       params
   })

export const getAuthoritiesById = (id: number): Promise<IResponse<IAuthorities>> =>
    client.get(`/Authorities/${id}`)

export const createAuthorities = (body: IAuthoritiesCreate) : Promise<IResponse<number>> =>
    client.post("/Authorities", body)

export const updateAuthorities = (body: IAuthoritiesUpdate): Promise<IResponse<string>> =>
    client.put("/Authorities", body)

export const deleteOrders = (ids: number[]): Promise<IResponse<boolean>> =>
    client.delete("/Authorities", {
      data: {
        ids
      }
    })
