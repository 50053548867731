import { FC } from "react"

import { Spin } from "antd"

import classes from "./PageLoader.module.scss"

export const PageLoader: FC = () => (
  <div className={classes["page-loader"]}>
    <img src="nordLoader.png"/>
    <Spin size="large" />
  </div>
)
