import React, { FC } from "react"

import { Navigate, Outlet } from "react-router-dom"

import { OIDC_LOGIN_DEFAULT } from "@/constants/oidcStorageKeys"
import { Resource, Scope } from "@/constants/permission"

import { usePermission } from "@/hook/usePermission"

import { paths } from "@/router/paths"

interface GuardRouteProps {
  resource: Resource;
  scope: Scope;
}

const GuardRoute: FC<GuardRouteProps> = ({ resource, scope }) => {

  const { hasPermission } = usePermission()

  if (hasPermission(resource, scope)) {
    return <Outlet />
  }

  else {
    const authorizationRedirectProps = JSON.parse(localStorage.getItem(OIDC_LOGIN_DEFAULT) ?? "")
    const authorizationRedirectPath = authorizationRedirectProps.callbackPath.split("/")[1]

    // если нет доступа к "запомненной" странице сразу после авторизации, редирект на "главную"
    if (resource === authorizationRedirectPath) {
      authorizationRedirectProps.callbackPath = paths.main
      localStorage.setItem(OIDC_LOGIN_DEFAULT, JSON.stringify(authorizationRedirectProps))
      return <Navigate to={paths.main} replace={true} />
    }
    else {
      return <Navigate to={paths.authorizationError} replace={true} />
    }
  }
}

export default GuardRoute
