import { ICustomerClient } from "./ICustomerClient"

import { ISelectListItem } from "@/types/ui"

export interface IDocumentFile {
  content: string
  name: string
}

export interface IDriver {
  id: number
  fullName: string
  firstName: string
  lastName: string
  isCitizenRussian: boolean
  typeIdentityDocument?: number
  "birthDate": string
  "birthPlace": string
  patronymic: string
  "passportSeries": string
  passportNumber: string
  passportIssuedBy: string
  passportDate: string
  passportDivisionCode: string
  region: string
  district: string
  settlement: string
  street: string
  house: string
  building: string
  apartment: string
  phone: string
  passportScans?: IDocumentFile[]
  approved: boolean
  verificationStatus: number
	verificationStatusText: string
  clients: ICustomerClient[]
}

export interface IDriverCreate {
  fullName: string
  firstName: string
  lastName: string
  isCitizenRussian: boolean
  typeIdentityDocument?: number
  birthDate: string
  birthPlace: string
  patronymic: string
  passportSeries: string
  passportNumber: string
  passportIssuedBy: string
  passportDate: string
  passportDivisionCode?: string
  region: string
  district: string
  settlement: string
  street: string
  house: string
  building: string
  apartment: string
  phone: string
  passportScans?: IDocumentFile[]
  approved: boolean
  clientIds: number[]
}

export interface IDriverSelectListItem extends ISelectListItem {
  verificationStatus: VerificationStatus
}

export enum VerificationStatus
{
    Awaits,
    Rejected,
    Confirmed
}

export enum IDriverTypeIdentityDocument {
  RussianPassport,
  RussianResidencePermit,
  RussianTemporaryIdentityCard
}

export const DriverTypeIdentityDocumentForSelect = [
  { id: IDriverTypeIdentityDocument.RussianPassport, value: "Паспорт гражданина РФ " },
  { id: IDriverTypeIdentityDocument.RussianResidencePermit, value: "Вид на жительство в РФ" },
  { id: IDriverTypeIdentityDocument.RussianTemporaryIdentityCard, value: "Временное удостоверение личности в РФ" }
]

export enum IDriverIsCitizenRussianFederation {
  Yes,
  No
}

export const DriverCitizenRussianFederationForSelect = [
  { id: IDriverIsCitizenRussianFederation.Yes, value: "является" },
  { id: IDriverIsCitizenRussianFederation.No, value: "не является" }
]

export interface IDriverUpdate extends IDriverCreate {
  id: number
}

export interface IDriverChangeVerificationStatus {
  id : number
  status : VerificationStatus
}

export const DRIVERS_KEY = "DRIVERS_KEY"
