import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import {
  Spin,
  Row,
  Col,
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select, FormInstance, FormRule
} from "antd"

import { NumericInput } from "@/components/_Shared/NumericInput"
import { getCustomer, getCustomers } from "@/http/customer"
import { createFinance, generateInvoice, getFinancesForGenerate, getInvoice } from "@/http/finance"

import { useDownloadFile } from "@/hook/useDownloadFile"

import { IRequestParams } from "@/types/api"
import { ICustomer } from "@/types/ICustomer"
import { IFinance, IFinanceCreate, IFinanceForGenerate, PaymentType, paymentTypes } from "@/types/IFinance"

import { getMoney } from "@/utils/formatNumber"


export interface FinanceGenerateInvoiceProps {
  open(finances?: IFinance[]): void
  close(): void
}

interface FinanceGenerateInvoiceValues {
  date: Date
  customerId: number
  paymentType: PaymentType
  email: string
  sum: number
}

export const FinanceGenerateInvoice = forwardRef<FinanceGenerateInvoiceProps>((props, ref) => {
  const [form] = Form.useForm<FinanceGenerateInvoiceValues>()
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [chosenCustomerId, setChosenCustomerId] = useState<number>()
  const [clients, setClients] = useState<string[]>([])
  const [finances, setFinances] = useState<IFinanceForGenerate[]>([])
  const [chosenFinanceId, setChosenFinanceId] = useState<number>()

  const [isOpen, setIsOpen] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [email, setEmail] = useState("")

  const { download: invoiceDownload, isLoading: isDownloading } =
    useDownloadFile(() => getInvoice(chosenFinanceId ?? 0))

  useImperativeHandle(ref, () => ({
    open(finances?: IFinance[]) {
      onOpenHandler(finances)
    },
    close() {
      onCloseHandler()
    }
  }))

  const title = useMemo(() => {
    if(isSent){
      return "Счет отправлен"
    }
    return "Получить счет"
  }, [isSent])

  const onOpenHandler = async (
    finances?: IFinance[]
  ) => {
    form.resetFields()
    form.setFieldValue("paymentType", paymentTypes[1].type)
    const customers = (await getCustomers()).response.data
    setCustomers(customers)
    const clients = customers?.map((x : ICustomer) => x.customerClientName).filter((value, index, array) => array.indexOf(value) === index) ?? []
    setClients(clients)

    setIsOpen(true)
  }

  const onCloseHandler = () => {
    form.resetFields()
    setIsSent(false)
    setIsOpen(false)
  }

  const onGetInvoice = async () => {
    try {
      await form.validateFields()

      const date = form.getFieldValue("date").toISOString()
      const paymentSum = Number(form.getFieldValue("paymentSumm"))
      const paymentType = form.getFieldValue("paymentType")
      const email = form.getFieldValue("email")

      let currentFinanceId = chosenFinanceId
      const chosenFinance = finances.find(x => x.id === currentFinanceId)

      if(!chosenFinance && chosenCustomerId){
        const financeCreate : IFinanceCreate = {
          date: date,
          sum: paymentSum,
          paymentType: paymentType,
          customerId: chosenCustomerId
        }
        const newFinanceId = ((await createFinance(financeCreate)).response)
        currentFinanceId = newFinanceId
        setChosenFinanceId(newFinanceId)
      }

      if (!currentFinanceId)
        return

      const newInvoiceData = {
        financeId: currentFinanceId,
        date: date,
        paymentSum: paymentSum,
        paymentType: paymentType,
        email: email
      }

      setEmail(email)
      setIsSaving(true)
      generateInvoice(newInvoiceData)
        .then(() => setIsSent(true))
        .finally(() => setIsSaving(false))
    } catch (errorInfo) {}
  }

  const onPaymentSumChange = () => {
    if(form.getFieldValue("order")){
       form.setFieldValue("order", undefined)
    }
  }

  const OnOrderChange = (value : number) => {
    form.setFieldValue("paymentSumm", finances.find(x => x.id == value)?.orderSum)
    setChosenFinanceId(value)
  }

  const onClientChange = (value: string) => {
    let params : IRequestParams = {}
    if(value){
      params = {
        Filter: `CustomerClientName = ${value}`
      }
    }

    form.resetFields(["customer"])
    if(form.getFieldValue("order") != undefined){
      form.resetFields(["paymentSumm"])
    }
    form.resetFields(["order"])

    getCustomer(params).then((response) => {
      setCustomers(response.response.data ?? [])
      return response.response.data
    }).then(response => {
      getFinancesForGenerate(response.map(c => c.id)).then((response) => {
        setFinances(response.response)
      })
    })
  }

  const onCustomerChange = (value : number) => {
    setChosenCustomerId(value)
    if(form.getFieldValue("order") != undefined){
      form.resetFields(["paymentSumm"])
    }
    form.resetFields(["order"])
    if (value) {
      getFinancesForGenerate([value]).then((response) => {
        setFinances(response.response)
      })
    } else {
      setFinances([])
    }
  }

  const customFormValidation = {
    isOrderSelected: [
      (formInstance: FormInstance) => ({
        message: "Пожалуйста выберите субклиента",
        validator(rule: FormRule, value : number) {
          if(value)
            return Promise.resolve()
          if(formInstance.getFieldValue("order")){
            return Promise.resolve()
          }
          return Promise.reject(new Error())
        }
      })
    ] as FormRule[]
  }

  const onDownloadClick = async () => {
     await invoiceDownload()
  }

  const onBackClicked = async () => {
    const finances = await getFinancesForGenerate(
      customers?.some(x => x != undefined) ? [...customers.map(c => c.id)] : []
    )
    form.resetFields()
    setIsSent(false)
    setFinances(finances.response)
  }

  return (
    <Drawer
      title={title}
      width={820}
      onClose={onCloseHandler}
      open={isOpen}
      styles={{ body: { paddingBottom: 80 } }}
    >
      {!isSent &&
      <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                  name="client"
                  label="Клиент"
              >
                <Select
                    placeholder="Клиент"
                    options={clients.map(t => ({
                      label: t,
                      value: t
                    }))}
                    allowClear
                    onChange={onClientChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
            <Form.Item
                name="customer"
                label="Субклиент"
                rules={ customFormValidation.isOrderSelected }
            >
              <Select
                  placeholder="Субклиент"
                  showSearch
                  options={customers.map(x => ({
                    label: x.name,
                    value: x.id
                  }))}
                  allowClear
                  onChange={onCustomerChange}
              />
            </Form.Item>
          </Col>
          </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
                name="order"
                label="Неоплаченный заказ"
            >
              <Select
                  onSelect= {OnOrderChange}
                  placeholder="Неоплаченный заказ"
                  options={finances?.map((ord) => ({
                    value: ord.id,
                    label: `${ord.orderNumber}, ${getMoney(ord.orderSum ?? 0)}`
                  }))}
                  allowClear
              />
            </Form.Item>
          </Col>
        </Row>
          <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                      name="paymentType"
                      label="Тип оплаты"
                      rules={[
                        { required: true, message: "Пожалуйста выберите тип оплаты" }
                      ]}
                  >
                    <Select
                        placeholder="Тип оплаты"
                        showSearch
                        options={paymentTypes.map((type) => ({
                          value: type.type,
                          label: type.name
                        }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

          <Row gutter={16}>
                <Col xs={24} >
                  <Form.Item
                      name="email"
                      label="Электронная почта"
                      rules={[
                        { required: true, message: "Пожалуйста введите электронную почту" }
                      ]}
                  >
                    <Input
                        placeholder="Электронная почта"
                        //defaultValue={customer?.name} //todo Add subclients add email
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name="date"
                    label="Дата"
                    rules={[
                      { required: true, message: "Пожалуйста выберите дату" }
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Дата заказа"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                  name="paymentSumm"
                  label="Сумма"
                  rules = {[
                    { required: true, message: "Пожалуйста введите сумму" }
                  ]}
              >
                <NumericInput
                    onChange={onPaymentSumChange}
                    placeholder="Сумма"
                />
              </Form.Item>
            </Col>
          </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={onGetInvoice}
                    disabled={isSaving}
                  >
                    Сформировать счет
                  </Button>
                </Col>
              </Row>
      </Form>
      }
      {isSent &&
          <Spin spinning={isDownloading}>
            <Row style={{ paddingBottom: "20px" }}>
              <Button
                  type="default"
                  onClick={onBackClicked}>
                Вернуться назад
              </Button>
            </Row>
            <Row style={{ paddingBottom: "20px" }}>
                <label>Отправлено на почту {email}</label>
              </Row>
            <Row>
                <Button
                    onClick={onDownloadClick}
                    type="primary"
                    size="large"
                    shape="round">
                  Скачать счет
                </Button>
            </Row>

          </Spin>
      }

    </Drawer>
  )
})
