import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { IFuelType, IFuelTypeCreate, IFuelTypeUpdate } from "@/types/IFuelType"
import { ITableColumn } from "@/types/ui"


export const getFuelType = (params: IRequestParams): Promise<IPagingResponse<IFuelType>> =>
  client.get("/FuelTypes", {
    params
  })

export const getFuelTypeById = (id: number): Promise<IResponse<IFuelType>> => client.get(`/FuelTypes/${id}`)

export const getFuelTypeByDepot = (depotId: number) : Promise<IResponse<IFuelType[]>> => client.get(`/FuelTypes/by-depot/${depotId}`)

export const createFuelType = (body: IFuelTypeCreate): Promise<IResponse<number>> => client.post("/FuelTypes", body)

export const updateFuelType = (body: IFuelTypeUpdate): Promise<IResponse<string>> => client.put("/FuelTypes", body)

export const getFuelTypeExcel = (params: IRequestParams, columns?: ITableColumn<IFuelType>[]): Promise<IResponse<IDownloadDocument>> => client.post("/FuelTypes/Excel",
    columns, {
        params
    })

export const deleteFuelTypes = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/FuelTypes", {
    data: {
      ids
    }
  })
