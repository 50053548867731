import { getInvoice } from "@/http/finance"

import { FinanceOperationType, IFinance } from "@/types/IFinance"
import { IInvoice } from "@/types/IInvoice"

export const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64)
  const length = binaryString.length
  const bytes = new Uint8Array(length)

  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer
}

export const downloadFile = async (file: any) => {
  const blob = new Blob([base64ToArrayBuffer(file.response.content)], {
    type: file.response.mimeType
  })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = `${file.response.name}.pdf`
  link.click()
  URL.revokeObjectURL(url)
}

export const documentDownload = async (
  data: IFinance,
  error: (e: any, duration?: number) => void
) => {
  switch (data.operationType) {
    //TODO add downloads for different documents
    case FinanceOperationType.Expenses:
      break
    case FinanceOperationType.Income:
      break
    case FinanceOperationType.Invoice:
      const invoiceFile = await getInvoice(data.id)
      await downloadFile(invoiceFile)
      break
    case FinanceOperationType.Cancelled:
      break
    default:
      error(
        {
          response: {
            data: {
              message: "Документы будут доступны после завершения заказа"
            }
          }
        },
        2
      )
      break
  }
}
