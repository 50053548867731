import { FC, useState } from "react"

import { InboxOutlined } from "@ant-design/icons"
import { Col, DatePicker, Row ,Form, Alert,Upload, UploadFile,Button } from "antd"
import { Dayjs } from "dayjs"

import { TimeUnloadFuelTable } from "@/components/TimeUnlodFuels/TimeUnloadFuelTable"

import { useTimeUnloadFuelMutation } from "@/hook/TimeUnloadFuel/useTimeUnloadFuelMutation"

const { Dragger } = Upload

const TimeUnloadFuelsPage:FC = () => {
    const [date, setDate] = useState<Date | undefined>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [isUploadComplete, setIsUploadComplete] = useState(false)


    const { uploadExcelMutation } = useTimeUnloadFuelMutation()

    const onDateChange = async (date: Dayjs, dateString: string | string[]) => {
        if (date) {
            const slotDate = new Date(date.format("YYYY-MM-DD")) 
            setDate(slotDate)
        }
        else {
            setDate(undefined)
        }
    }

    const onFileListChange = async (info: any) => {
        const filteredFiles = info.fileList.filter(
            (file: UploadFile) => file.name.endsWith(".xls") || file.name.endsWith(".xlsx")
        )
    
        const filesWithContent = await Promise.all(
            filteredFiles.map((file: UploadFile) => {
                return new Promise<UploadFile>((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        file.url = e.target?.result as string
                        resolve(file)
                    }
                    reader.onerror = reject
                    reader.readAsDataURL(file.originFileObj as Blob)
                })
            })
        )
    
        setFileList(filesWithContent)
    }

    const handleUpload = async () => {
        await uploadExcelMutation.mutateAsync(fileList.map((file) => ({
            content: file.url ? (file.url as string).split("base64,")[1] : "",
            name: file.name
          })))
          setIsUploadComplete(true)
          setFileList([])
    }

    return(
        <>
            <Row>
                <Col >
                    <Form.Item label="Дата">
                        <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Дата заказа"
                        allowClear={false}                        
                        onChange={onDateChange}/>
                    </Form.Item>
                </Col>
            </Row> 
            <Row>
                <Col >
                    {date ? <TimeUnloadFuelTable date={date} isUploadComplete={isUploadComplete} setiIsUploadComplete={setIsUploadComplete} /> 
                    : <Alert message="Выберите дату" type="warning" showIcon />}
                </Col>
            </Row>
            <Row style={{ marginTop : 8 }}>
                <Col>
                    <Dragger 
                    height={150}
                    onChange={onFileListChange}
                    beforeUpload={()=>false}
                    accept=".xls,.xlsx"
                    fileList={fileList}
                    multiple>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Кликните или перетащите файл в эту область для загрузки</p>
                    </Dragger>
                    {fileList.length > 0 && (
                        <Button 
                          type="primary" 
                          style={{ marginTop: 16 }} 
                          onClick={handleUpload}
                        >
                          Отправить файлы
                        </Button>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default TimeUnloadFuelsPage