import { createContext, FC, useEffect, useState } from "react"

import { PageLoader } from "@/components/_Shared/PageLoader"
import { getPermissions } from "@/http/permissions"

import { IPermission } from "@/types/IPermission"

const PermissionContext = createContext<IPermission[]>([])

const PermissionContextProvider: FC<any> = ({ children }) => {

  const [permissions, setPermissions] = useState<IPermission[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {

    getPermissions()
      .then(resp => {
        setPermissions(resp.response)
      })
      .catch(err => {
        // todo: или же здесь отлавливать 401 статус, чтобы разлогинить "зависшего" юзера.
        // Запрос отдает просто ошибку сети
      })
      .finally(() => {
        setIsLoading(false)
      })

  }, [])

  if(isLoading) return (
    <PageLoader/>
  )
  
  return (
    <PermissionContext.Provider value={permissions}>
      {children}
    </PermissionContext.Provider>
  )
}
export { PermissionContext, PermissionContextProvider }