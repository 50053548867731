import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/_Shared/FilterWrapper"

import { useFilterStorage } from "@/hook/useFilterStorage"

import { CUSTOMER_CATEGORIES_KEY } from "@/types/ICustomerCategory"
import { FilterField, FilterFieldOperator, FilterFieldType } from "@/types/ui"


export const useCustomerCategoryFilters = () => {
  const [filters, setFilters] = useState<FilterField[]>([])

  const { getFilter } = useFilterStorage({
    key: CUSTOMER_CATEGORIES_KEY
  })

  const oldFilters: FieldData[] = getFilter()

  useEffect(() => {
    setFilters([
      ...CustomerCategoryFilters
    ])
  }, [])

  return {
    filters: filters.map(f => {
      const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      return {
        ...f, value: f.type === FilterFieldType.Date
          ? dayjs(new Date(currentFilter.value as string))
          : currentFilter.value
      }
    })
  }
}

const CustomerCategoryFilters: FilterField[] = [
  {
    text: "Наименование",
    name: "name",
    type: FilterFieldType.Text,
    operator: FilterFieldOperator.Contains
  }
]
