import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { IFuelLimit, IFuelLimitCreate, IFuelLimitUpdate, IFuelLimitCard, IFuelLimitCategorySettingRequest } from "@/types/IFuelLimit"
import { ITableColumn } from "@/types/ui"


export const getFuelLimits = ( params: IRequestParams): Promise<IPagingResponse<IFuelLimit>> =>
  client.get("/FuelLimits", {
    params
  })

export const getFuelLimitById = (id: number): Promise<IResponse<IFuelLimit>> =>
    client.get(`/FuelLimits/${id}`)

export const getFuelLimitCardById = (id: number): Promise<IResponse<IFuelLimitCard>> =>
  client.get(`/FuelLimits/card/${id}`)

export const createFuelLimit = (
    body: IFuelLimitCreate
): Promise<IResponse<number>> => client.post("/FuelLimits", body)

export const updateFuelLimit = (
    body: IFuelLimitUpdate
): Promise<IResponse<string>> => client.put("/FuelLimits", body)

export const getFuelLimitsExcel = (params: IRequestParams, columns?: ITableColumn<IFuelLimit>[]):
  Promise<IResponse<IDownloadDocument>> =>
  client.post("/FuelLimits/Excel",
    columns, {
    params
  })

export const deleteFuelLimits = (ids: number[]): Promise<IResponse<boolean>> =>
    client.delete("/FuelLimits", {
        data: {
            ids
        }
    })

export const editFuelLimitSettingsCategory = (
  body: IFuelLimitCategorySettingRequest[]
): Promise<IResponse<boolean>> => client.post("/FuelLimits/categorySettings", { settings : body })

