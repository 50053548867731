import { IRefusalAnalytics } from "@/types/IAnalytics"
import { ITableColumn } from "@/types/ui"

export const orderRefusalColumns = (sorter: boolean): ITableColumn<IRefusalAnalytics>[] => [
  {
    title: "Период",
    key: "period",
    dataIndex: ["period"],
    sorter: false,
    isVisible: true,
    order: 1
  },
  {
    title: "Количество отказов",
    key: "refusalCount",
    dataIndex: ["refusalCount"],
    sorter: sorter,
    isVisible: true,
    order: 2
  },
  {
    title: "Объем отказов",
    key: "refusalAmount",
    dataIndex: ["refusalAmount"],
    sorter: sorter,
    isVisible: true,
    order: 3
  }
]
