
import { DeliveryType, deliveryTypes } from "@/types/IOrder"
import { IOrderReport, Period } from "@/types/IOrderReport"
import { ITableColumn } from "@/types/ui"

import { formatFuelAmount, getMoney } from "@/utils/formatNumber"

export const orderReportColumns: ITableColumn<IOrderReport>[] = [
  {
    title: "Период",
    key: "period",
    dataIndex: ["period"],
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Количество заказов",
    key: "orderCount",
    dataIndex: ["orderCount"],
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Сумма заказов",
    key: "orderSum",
    dataIndex: ["orderSum"],
    render: (_, { orderSum }) => <><span>{getMoney(orderSum)}</span></>,
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Объём заказов",
    key: "orderAmount",
    dataIndex: ["orderAmount"],
    render: (_, { orderAmount }) => formatFuelAmount(orderAmount),
    sorter: true,
    isVisible: true,
    order: 4
  }
]

export const periods = [
  {
    label: "Год",
    value: Period.Year
  },
  {
    label: "Квартал",
    value: Period.Quarter
  },
  {
    label: "Месяц",
    value: Period.Month
  },
  {
    label: "Неделя",
    value: Period.Week
  },
  {
    label: "День",
    value: Period.Day
  }
]

export const deliveries = deliveryTypes.map((delivery) => ({
  label: delivery.name,
  value: delivery.type as number
}))

deliveries.unshift({
  label: "Любой",
  value: -1
})
