import { useQuery } from "@tanstack/react-query"

import { DEPOT_QUERY_KEY, DEPOT_LIST_QUERY_KEY } from "@/constants/query"
import { getDepotById, getDepots, getDepotsSelectList } from "@/http/depot"

import { IRequestParams } from "@/types/api"


export const useDepotQuery = (params?: IRequestParams) => {
  return useQuery({
      queryFn: () => getDepots(params || {}),
      queryKey: [DEPOT_QUERY_KEY, params],
      retry: false
    })
}

export const useDepotSelectListQuery = () => {
    return useQuery({
        queryFn: () => getDepotsSelectList(),
        queryKey: [DEPOT_LIST_QUERY_KEY],
        retry: false
    })
}

export const useDepotByIdQuery = (id: number)  => {
    return useQuery({
        queryFn: () => getDepotById(id),
        queryKey: [DEPOT_QUERY_KEY, id],
        enabled: id !== 0
    })
}
