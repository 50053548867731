import { useQuery } from "@tanstack/react-query"

import { FINANCE_QUERY_KEY } from "@/constants/query"
import { getFinance, getFinanceByOrderId, getFinancesForGenerate } from "@/http/finance"

import { IRequestParams } from "@/types/api"


export const useFinanceQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getFinance(params || {}),
        queryKey: [FINANCE_QUERY_KEY, params],
        retry: false
    })
}

export const useFinancesForGenerateQuery = (customerIds: number[]) => {
  return useQuery({
    queryFn: () => getFinancesForGenerate(customerIds),
    queryKey: [FINANCE_QUERY_KEY, customerIds],
    retry: false
  })
}

export const useFinanceByOrderIdQuery = (orderId: number) => {
  return useQuery({
    queryFn: () => getFinanceByOrderId(orderId),
    queryKey: [FINANCE_QUERY_KEY, orderId],
    enabled: orderId !== 0,
    retry: false
  })
}
