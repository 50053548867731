
import { Space } from "antd"
import { NavLink } from "react-router-dom"

import { ITransport } from "@/types/ITransport"
import { ITableColumn } from "@/types/ui"

import { paths } from "@/router/paths"

export const transportColumns: ITableColumn<ITransport>[] = [
  {
    title: "Номер записи",
    key: "id",
    dataIndex: ["id"],
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Номер",
    key: "number",
    dataIndex: ["number"],
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Тип",
    key: "type",
    dataIndex: ["type"],
    sorter: true,
    isVisible: true,
    order: 4
  },
  {
    title: "Модель",
    key: "model",
    dataIndex: ["model"],
    sorter: true,
    isVisible: true,
    order: 5
  },
  {
    title: "Вид транспорта",
    key: "class",
    dataIndex: ["className"],
    sorter: true,
    isVisible: true,
    order: 6
  },
  {
    title: "Статус проверки",
    key: "verificationStatusText",
    dataIndex: ["verificationStatusText"],
    sorter: true,
    isVisible: true,
    order: 7
  }
]

export const additionalTransportColumns: ITableColumn<ITransport>[] = [
  {
    title: "Клиенты",
    key: "clients",
    dataIndex: ["clients"],
    align: "center",
    render: (_, transport: ITransport) => (
      <>
        <Space direction="vertical" size="small" >
          {
            transport.clients &&
            transport.clients.map((client) => {
              return (
                <NavLink to={paths.customerClientById(client.id)}>{client.name}</NavLink>
              )
            })
          }
        </Space>
      </>
    ),
    width: 100,
    sorter: false,
    isVisible: true,
    order: 2
  }
]
