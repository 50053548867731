import { OrderStatus } from "@/types/IOrder"

export const orderBackDatingStatus = [
    {
      label: "Закрыт",
      value: OrderStatus.Closed
    },
    {
      label: "Подтвержден",
      value: OrderStatus.Approved
    },
    {
      label: "Отгружен",
      value: OrderStatus.Shipped
    },
    {
      label: "На загрузке",
      value: OrderStatus.OnLoading
    }
  ]

  export  const optionsRadioFuel = [
    { label: "Литр", value: "litr" },
    { label: "Тонна", value: "ton" }
  ]