import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"


import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { Button, Form, FormInstance, Input, InputNumber, InputRef, Popconfirm, Table as AntTable, Alert, Space } from "antd"

import { Table } from "@/components/_Shared/Table"

import { useFuelLimitsMutation } from "@/hook/FuelLimits/useFuelLimitsMutation"

import { IFuelLimitCard, IFuelLimitCategorySetting, IFuelLimitCategorySettingRequest, IFuelLimitCategorySettingTotals } from "@/types/IFuelLimit"
import { ITableColumn } from "@/types/ui"

import { formatNumber } from "@/utils/formatNumber"

export interface FuelLimitSettingsTableProps {
  settings: IFuelLimitCategorySetting[]
  fuelLimit: IFuelLimitCard
  editable: boolean
  refetchCallback: Function
}
interface EditFormValues {}

//#region Editable Tabel Row and Cell (todo: abstract)
interface EditableRowProps {
  index: number
}
const EditableContext = createContext<FormInstance<any> | null>(null)

  const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
    const [formItem] = Form.useForm<EditFormValues>()

    return (
      <Form form={formItem} component={false}>
        <EditableContext.Provider value={formItem}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }
  
  interface EditableCellProps<T> {
    title: ReactNode
    editable: boolean
    children: ReactNode
    dataIndex: keyof T
    record: T
    handleSave: (record: T) => void,
    defaultValue?: string | number,
    inputType?: "string" | "number",
    outerChangesCount?: number
  }

  // const lambda function
// const Abc: <T extends Object>(p: EditableCellProps<T>) => FC<EditableCellProps<T>> = (props) => {
//   return <div />
// };

//export const EditableCell: FC<EditableCellProps<ICategoryFuelSetting>> = ({

//type IEditableCell<T = Object> = React.FC<EditableCellProps<T>>

const EditableCell: FC<EditableCellProps<IFuelLimitCategorySetting>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    defaultValue,
    inputType,
    outerChangesCount,
    ...restProps
  }) => {
    const inputRef = useRef<InputRef>(null)
    const inputNumberRef = useRef<HTMLInputElement>(null)
    const form = useContext(EditableContext)!

    const [isformTouched, setIsFormTouched] = useState<boolean>(false)

    const getIsShowFormValidations = () : boolean => {
      return (outerChangesCount ? outerChangesCount > 0 : false) && isformTouched
    }

    const [isShowFormValidation, setIsShowFormValidation] = useState<boolean>(getIsShowFormValidations)

    useEffect(() => {
      if(outerChangesCount !== null && outerChangesCount === 0) {
        setIsFormTouched(false)
        //form.resetFields()
      }
      setIsShowFormValidation(getIsShowFormValidations)
      // eslint-disable-next-line 
    }, [outerChangesCount])

    const saveOnChange = async (e: any) => {
      try {
        const values = await form.validateFields()
        setIsFormTouched(true)
        setIsShowFormValidation(getIsShowFormValidations)
        handleSave({ ...record, ...values })
      } catch (errInfo) {
        // eslint-disable-next-line 
        console.log("SaveOnChange failed:", errInfo)
      }
    }

    let childNode = children
    if (editable) {
      childNode = (
        <Form.Item
          initialValue={record[dataIndex] === null ? defaultValue : record[dataIndex]}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} необходимо заполнить`
            }
          ]}
        >
          {(inputType && inputType === "number") &&
            <InputNumber ref={inputNumberRef} onPressEnter={saveOnChange} onChange={saveOnChange} style={{ width: "100%" }}
              status={ isShowFormValidation ? "warning" : "" }
              prefix={ isShowFormValidation ? <ClockCircleOutlined /> : <CheckCircleOutlined style={{ color: "green" }}/> }
              />
          }
          {(!inputType || inputType === "string") &&
            <Input ref={inputRef} onPressEnter={saveOnChange} onChange={saveOnChange}
              status={isShowFormValidation ? "warning" : ""}
              prefix={ isShowFormValidation ? <ClockCircleOutlined /> : <CheckCircleOutlined style={{ color: "green" }}/> }
            />
          }
        </Form.Item>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }
//#endregion

export const FuelLimitSettingsTable: FC<FuelLimitSettingsTableProps> = (props) => {
  const { settings, fuelLimit, editable, refetchCallback } = props

  const { editFuelLimitSettingsMutation } = useFuelLimitsMutation()

  const [dataSourceSettings, setDataSourceSettings] = useState<IFuelLimitCategorySetting[]>(settings)
  const [settingTotals, setSettingTotals] = useState<IFuelLimitCategorySettingTotals>({ categoryPercentSum: 0, categoryLimitSum: 0, freeLimitSum: 0, orderedLimitSum: 0 })

  const [limitOverflowWarning, setLimitOverflowWarning] = useState(false)
  const [enabledEdit, setEnabledEdit] = useState(editable)
  const [isProcessing, setIsProcessing] = useState(false)
  const [changedSettingsArray, setChangedSettingsArray] = useState<IFuelLimitCategorySetting[]>([])
  const [changedArrayLength, setChangedArrayLength] = useState<number>(0)

  const editComponents = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  useEffect(() => {
    settings.forEach(s=> {
      s.categoryLimit = Number(formatNumber(s.categoryLimit))
      s.orderedLimit = Number(formatNumber(s.orderedLimit))
      s.freeLimit = Number(formatNumber(s.categoryLimit - s.orderedLimit))
    })
    setDataSourceSettings(settings)
    resetTotals()
  }, [settings])

  const resetTotals = () => {
    const totals: IFuelLimitCategorySettingTotals = { categoryPercentSum: 0, categoryLimitSum: 0, freeLimitSum: 0, orderedLimitSum: 0 }

    settings.forEach(s=> {
      totals.categoryPercentSum += s.categoryPercent ?? 0
      totals.categoryLimitSum += s.categoryLimit ?? 0
      totals.orderedLimitSum += s.orderedLimit ?? 0
      totals.freeLimitSum += s.freeLimit ?? 0})
    setSettingTotals(totals)
    setLimitOverflowWarning(totals.categoryLimitSum > fuelLimit.limit)
  }

  const handleSaveToStack = (row: IFuelLimitCategorySetting) => {
    const findChangedItem = changedSettingsArray.filter(s => s.categoryId === row.categoryId)

    if(findChangedItem.length > 0) {
      findChangedItem[0].categoryLimit = row.categoryLimit
      findChangedItem[0].freeLimit = row.categoryLimit - findChangedItem[0].orderedLimit
    }
    else {
      changedSettingsArray.push(row)
    }
    setChangedArrayLength(changedSettingsArray.length)

    // обновление данных в таблице (для изменения финальной цены)
    const tempData = [...dataSourceSettings]
    const changed = tempData.filter(s => s.categoryId === row.categoryId)

    changed[0].categoryLimit = row.categoryLimit
    changed[0].freeLimit = row.categoryLimit - changed[0].orderedLimit
    setDataSourceSettings(tempData)
    resetTotals()
    // -----
  }

  const onFinishSubmitHandler = () => {
    setChangedArrayLength(0)
    setChangedSettingsArray([])
  }

  const tableColumns : ITableColumn<IFuelLimitCategorySetting>[] = [
    {
      title: "Категория",
      dataIndex: "categoryName",
      key: "categoryName",
      isVisible: true,
      width: 250,
      order: 1
    },
    {
      title: "% объема по умолчанию",
      key: "categoryPercent",
      dataIndex: "categoryPercent",
      isVisible: true,
      width: 200,
      order: 2
    },
    {
      title: "Абс. количество",
      dataIndex: "categoryLimit",
      key: "categoryLimit",
      isVisible: true,
      width: 150,
      order: 3,
      editable: enabledEdit,
      onCell: (record: IFuelLimitCategorySetting) => ({
        record,
        editable: enabledEdit,
        dataIndex: "categoryLimit",
        title: "'Абс. количество'",
        handleSave: handleSaveToStack,
        defaultValue: 0,
        inputType: "number",
        outerChangesCount: changedArrayLength
      })
    },
    {
      title: "В заказах",
      dataIndex: "orderedLimit",
      key: "orderedLimit",
      isVisible: true,
      width: 180,
      order: 3
    },
    {
      title: "Остаток",
      dataIndex: "freeLimit",
      key: "freeLimit",
      isVisible: true,
      width: 150,
      order: 3
    },
    {
      title: "",
      dataIndex: "",
      key: "empty",
      isVisible: true,
      order: 3
    },
    {
      title: "fuelId",
      dataIndex: "fuelId",
      key: "fuelId",
      isVisible: false,
      order: 3
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      isVisible: false,
      order: 4
    },
    {
      title: "categoryId",
      dataIndex: "categoryId",
      key: "categoryId",
      isVisible: false,
      order: 4
    }
  ]
  
  const submitFormStack = async () => {
    try {
      const request: IFuelLimitCategorySettingRequest[] = []
      changedSettingsArray.forEach(s=> {
        request.push(
          {
            id: s.id,
            fuelPeriodLimitId: s.fuelPeriodLimitId,
            categoryId: s.categoryId,
            categoryLimit: s.categoryLimit
          }
        )
      })
      
      setIsProcessing(true)

      await editFuelLimitSettingsMutation.mutateAsync(request)

      onFinishSubmitHandler()
      refetchCallback()
    } finally {
      setIsProcessing(false)
    }
  }

  const onConfirmEditHandler = () => {
    setEnabledEdit(true)
  }

  return (
    <>
      {settings.length > 0 &&
        <>
          {!enabledEdit &&
            <Popconfirm onConfirm={onConfirmEditHandler}
              title="Редактировать"
              description="Редактирование отключит глобальные настройки категорий."
              okText="Продолжить"
              cancelText="Отмена">
              <Button type="primary">Редактировать</Button>
            </Popconfirm>
          }
          {enabledEdit &&
            <Space>
              <Button
                  type="primary"
                  disabled={isProcessing || (changedArrayLength === 0) || changedSettingsArray.some(s => s.categoryLimit < 0)}
                  onClick={submitFormStack}>
                Сохранить
              </Button>
            
              {limitOverflowWarning &&
                <Alert message="Внимание! Сумма лимитов по категориям превысила установленный лимит на период. После сохранения лимит на период будет увеличен." type="warning" />
              }
            </Space>
          }
          <Table
            components={editComponents}
            columns={tableColumns}
            rowKey="categoryId"
            dataSource={dataSourceSettings}
            isSelectable={false}
            pagination={{
              currentPage: 1,
              pageSize: dataSourceSettings.length,
              total: dataSourceSettings.length
            }}
            summary={() => {
              return (
                  <AntTable.Summary fixed={"bottom"}>
                    <AntTable.Summary.Row>
                      <AntTable.Summary.Cell index={0}>Итого:</AntTable.Summary.Cell>
                      <AntTable.Summary.Cell index={1}>
                        <strong style={{ color: (settingTotals.categoryPercentSum > 100 ? "#ff4d4f" : "") }}>{formatNumber(settingTotals.categoryPercentSum)} %</strong>
                      </AntTable.Summary.Cell>
                      <AntTable.Summary.Cell index={2}>
                        <strong style={{ color: (settingTotals.categoryLimitSum > fuelLimit.limit ? "#ff4d4f" : "") }}>{formatNumber(settingTotals.categoryLimitSum)}</strong>
                      </AntTable.Summary.Cell>
                      <AntTable.Summary.Cell index={3}>
                        <strong style={{ color: (settingTotals.orderedLimitSum > fuelLimit.limit ? "#ff4d4f" : "") }}>{formatNumber(settingTotals.orderedLimitSum)}</strong>
                      </AntTable.Summary.Cell>
                      <AntTable.Summary.Cell index={4}>
                        <strong style={{ color: (settingTotals.freeLimitSum < 0 ? "#ff4d4f" : "") }}>{formatNumber(settingTotals.freeLimitSum)}</strong>
                      </AntTable.Summary.Cell>
                    </AntTable.Summary.Row>
                  </AntTable.Summary>
              )
            }
          }
          />
        </>
      }
    </>
  )
}
