import { IDriver } from "@/types/IDriver"
import { ITransport, ITransportClass } from "@/types/ITransport"

export const formatDriver = (driver?: IDriver) => {
  return `${driver?.fullName ?? ""}`
}

export const formatTransport = (transport?: ITransport) => {
  if (!transport)
    return ""

  const liter = transport.class === ITransportClass.Truck ? ""
    : `, ${transport.liter ?? "не установлено"} Л`

  return `${transport.type}, ${transport.model}, ${transport.number}${liter}`
}

export const formatNulableValue = (value: string | null | undefined) => {
  return value ? value : "-"
}

export const formatLongStringValue = (value: string | null | undefined) => {
  const maxVisibaleLen = 80
  const moreDots = "..."

  return !value ?
    formatNulableValue(value) :
    value?.length > maxVisibaleLen ? value.substring(0, maxVisibaleLen) + moreDots : value
}
