import { UploadFile } from "antd"
import { RcFile } from "antd/es/upload/interface"
import { uid } from "uid"

import { getBase64 } from "@/utils/getBase64"

export const uploadFileConvert = async (file: UploadFile) => {
  let content = file.url || file.thumbUrl || file.preview

  if (!content) {
    content = await getBase64(file.originFileObj as RcFile)
  }

  // Для всех картинок ставится типа jpeg в url. Если это как-то влияет, то можно с названия файла брать расширение
  return {
    uid: uid(),
    name: file.name,
    url: `data:image/jpeg;base64,${content}`
  }
}