import { useQuery } from "@tanstack/react-query"

import { FUEL_QUERY_KEY } from "@/constants/query"
import {
  IFuelForOrderParam,
  IFuelsByDepotParam,
  getFuelForOrder,
  getFuelRecordsByDepotForCustomer,
  getFuel,
  getFuelById,
  IFuelRecordsByDepotParam,
  getFuelsForOrderByDepot,
  getFuelsSelectList
} from "@/http/fuel"

import { IRequestParams } from "@/types/api"

export const useFuelQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getFuel(params || {}),
    queryKey: [FUEL_QUERY_KEY, params]
  })
}

export const useFuelByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getFuelById(id),
    queryKey: [FUEL_QUERY_KEY, id],
    enabled: id !== 0
  })
}

export const useFuelForOrderQuery = (params: IFuelForOrderParam) => {
  return useQuery({
    queryFn: () => getFuelForOrder(params),
    queryKey: [FUEL_QUERY_KEY, params],
    retry: false
  })
}

export const useFuelForOrderByDepotQuery = (params: IFuelsByDepotParam) => {
  return useQuery({
    queryFn: () => getFuelsForOrderByDepot(params),
    queryKey: [FUEL_QUERY_KEY, params],
    retry: false
  })
}

export const useFuelsByDepotQuery = (params: IFuelRecordsByDepotParam) => {
  return useQuery({
    queryFn: () => getFuelRecordsByDepotForCustomer(params),
    queryKey: [FUEL_QUERY_KEY, params],
    retry: false
  })
}

export const useFuelsSelectListQuery = () => {
  return useQuery({
    queryFn: () => getFuelsSelectList(),
    queryKey: [FUEL_QUERY_KEY],
    retry: false
  })
}
