import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ICustomer, ICustomerByClient, ICustomerCreate, ICustomerSelectListItem, ICustomerUpdate } from "@/types/ICustomer"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ITableColumn } from "@/types/ui"

export interface ICustomerParam {
  id: number
}

export const getCustomer = (params: IRequestParams): Promise<IPagingResponse<ICustomer>> =>
  client.get("/Customers", {
    params
  })

export const getCustomers = (): Promise<IPagingResponse<ICustomer>> =>
  client.get("/Customers")

export const getCustomersSelectList = (): Promise<IResponse<ICustomerSelectListItem[]>> => client.get("/Customers/selectList")

export const getCustomersByClient = (params: IRequestParams,  id: number): Promise<IPagingResponse<ICustomerByClient>> =>
  client.get(`/Customers/byClient/${id}`, {
    params
  })

export const getCustomerById = (id: number): Promise<IResponse<ICustomer>> => client.get(`/Customers/${id}`)
export const getCustomerExcel = (params: IRequestParams, columns?: ITableColumn<ICustomer>[]): Promise<IResponse<IDownloadDocument>> => client.post("/Customers/Excel", columns, {
    params
})

export const createCustomer = (body: ICustomerCreate): Promise<IResponse<number>> => client.post("/Customers", body)

export const updateCustomer = (body: ICustomerUpdate): Promise<IResponse<string>> => client.put("/Customers", body)

export const deleteCustomers = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Customers", {
    data: {
      ids
    }
  })
