import {
  ANALYTICS_DELIVERIES_PICKUPS_GET_QUERY_KEY,
  ANALYTICS_ORDERS_GET_QUERY_KEY,
  ANALYTICS_PRICES_COMPARISON_PERIOD_QUERY_KEY,
  ANALYTICS_PRICES_GET_QUERY_KEY,
  ANALYTICS_REFUSALS_GET_QUERY_KEY,
  ANALYTICS_SALES_GET_QUERY_KEY,
  getExcelQueryKey
} from "@/constants/query"
import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import {
  AnalyticsPeriod,
  ComparisonPeriod,
  IAnalyticsByCustomerParams,
  IAnalyticsParams,
  IAnalyticsRefusal,
  IOrderAnalytics,
  OrderDiagramAnalytics,
  IPriceAnalytics, IRefusalAnalytics, IDeliveriesPickupsAnalytics, IDeliveriesPickupsDiagramAnalytics
} from "@/types/IAnalytics"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ITableColumn } from "@/types/ui"

export const getOrderAnalytics = (analyticsParams: IAnalyticsByCustomerParams, params?: IRequestParams)
  : Promise<IPagingResponse<IOrderAnalytics>> =>
  client.post(ANALYTICS_ORDERS_GET_QUERY_KEY, analyticsParams, {
    params: params
  })

export const getOrderAnalyticsExcel = (
  analyticsParams: IAnalyticsByCustomerParams,
  columns: ITableColumn<OrderDiagramAnalytics>[],
  params?: IRequestParams): Promise<IResponse<IDownloadDocument>> =>
  client.post(getExcelQueryKey(ANALYTICS_ORDERS_GET_QUERY_KEY),
    {
      optionsList: analyticsParams,
      columns: columns
    },
    {
      params: params
    })


export const getSalesAnalytics = (analyticsParams: IAnalyticsByCustomerParams, params?: IRequestParams)
  : Promise<IPagingResponse<IOrderAnalytics>> =>
  client.post(ANALYTICS_SALES_GET_QUERY_KEY, analyticsParams, {
    params: params
  })

export const getSalesAnalyticsExcel = (
  analyticsParams: IAnalyticsParams,
  columns: ITableColumn<OrderDiagramAnalytics>[],
  params?: IRequestParams): Promise<IResponse<IDownloadDocument>> =>
  client.post(getExcelQueryKey(ANALYTICS_SALES_GET_QUERY_KEY),
    {
      optionsList: analyticsParams,
      columns: columns
    },
    {
      params: params
    })


export const getAnalyticsPrice = (options: IAnalyticsByCustomerParams): Promise<IPagingResponse<IPriceAnalytics>> =>
  client.post(ANALYTICS_PRICES_GET_QUERY_KEY, options)

export const getAnalyticsPriceExcel = (params: IAnalyticsByCustomerParams): Promise<IResponse<IDownloadDocument>> =>
  client.post(getExcelQueryKey(ANALYTICS_PRICES_GET_QUERY_KEY), { optionsList: params, columns: [] })


export const getAnalyticsRefusal = (options: IAnalyticsByCustomerParams, params?: IRequestParams)
  : Promise<IPagingResponse<IRefusalAnalytics>> =>
  client.post(ANALYTICS_REFUSALS_GET_QUERY_KEY, options, { params: params })

export const getAnalyticsRefusalExcel = (
  analyticsParams: IAnalyticsByCustomerParams,
  columns: ITableColumn<IRefusalAnalytics>[],
  params?: IRequestParams): Promise<IResponse<IDownloadDocument>> =>
  client.post(getExcelQueryKey(ANALYTICS_REFUSALS_GET_QUERY_KEY),
    {
      optionsList: analyticsParams,
      columns: columns
    },
    {
      params: params
    })


export const getAnalyticsDeliveriesPickups = (options: IAnalyticsByCustomerParams, params?: IRequestParams)
  : Promise<IPagingResponse<IDeliveriesPickupsAnalytics>> =>
  client.post(ANALYTICS_DELIVERIES_PICKUPS_GET_QUERY_KEY, options, { params: params })

export const getAnalyticsDeliveriesPickupsExcel = (
  analyticsParams: IAnalyticsParams,
  columns: ITableColumn<IDeliveriesPickupsDiagramAnalytics>[],
  params?: IRequestParams): Promise<IResponse<IDownloadDocument>> =>
  client.post(getExcelQueryKey(ANALYTICS_DELIVERIES_PICKUPS_GET_QUERY_KEY),
    {
      optionsList: analyticsParams,
      columns: columns
    },
    {
      params: params
    })



export const GetPeriodForComparison = (period: AnalyticsPeriod): Promise<IResponse<ComparisonPeriod[]>> =>
  client.get(`${ANALYTICS_PRICES_COMPARISON_PERIOD_QUERY_KEY}?period=${period}`)

