import { IFinance } from "@/types/IFinance"
import { ITableColumn } from "@/types/ui"

import { formatDate } from "@/utils/formatDate"
import { getMoney } from "@/utils/formatNumber"




export const financeColumns: ITableColumn<IFinance>[] = [
  {
    title: "Номер записи",
    key: "id",
    dataIndex: ["id"],
    sorter: true,
    width: 100,
    isVisible: false,
    order: 1
  },
  {
    title: "Дата",
    key: "date",
    dataIndex: ["date"],
    width: 100,
    sorter: true,
    isVisible: true,
    order: 2,
    render: (_, { date }) => <>{ date && <span>{formatDate(date)}</span> }</>
  },
  {
    title: "Клиент",
    key: "customerClientName",
    dataIndex: ["customer", "customerClientName"],
    sorter: true,
    width: 100,
    isVisible: true,
    order: 3
  },
  {
    title: "Субклиент",
    key: "customerName",
    dataIndex: ["customer", "name"],
    sorter: true,
    width: 100,
    isVisible: true,
    order: 4
  },
  {
    title: "Тип операции",
    key: "operationType",
    dataIndex: ["operationTypeText"],
    sorter: true,
    width: 100,
    isVisible: true,
    order: 5
  },
  {
    title: "Номер заказа",
    key: "orderNumber",
    dataIndex: ["order","number"],
    sorter: true,
    width: 100,
    isVisible: true,
    order: 6
  },
  {
    title: "Сумма",
    key: "sum",
    dataIndex: ["sum"],
    render: (_, { sum }) => <><span>{getMoney(sum)}</span></>,
    sorter: true,
    width: 100,
    isVisible: true,
    order: 7
  }
]
