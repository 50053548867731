import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts"

import { diagramColors, IAnalyticsPageDisplayUnit } from "@/types/IAnalytics"


export interface AnalyticsBarChartProps {
  data: any[] | undefined
  dataKeys: IAnalyticsPageDisplayUnit[]
  colors?: string[]
}

export const AnalyticsBarChart = (props: AnalyticsBarChartProps) => {
  const { data, dataKeys, colors } = props

  return (
    <BarChart width={700} height={400} data={data}>
      <XAxis dataKey="period"/>
      <YAxis/>
      <Tooltip/>
      <Legend/>
      <CartesianGrid stroke="#f5f5f5"/>
      {
        dataKeys.map((data, index) => (
          <Bar
            key={index}
            minPointSize={1}
            dataKey={data.value}
            name={data.bigLabel}
            fill={(colors ?? diagramColors)[index]}
            label={{ fill: "white" }}/>
        ))
      }
    </BarChart>
  )
}
