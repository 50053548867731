import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { ForwardedRef } from "react"

import { QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Checkbox, Drawer, Form, Input, Select, Skeleton,Tooltip } from "antd"

import { NumericInput } from "@/components/_Shared/NumericInput"
import { Resource, Scope } from "@/constants/permission"

import { useCustomerQuery, useCustomersSelectListQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useDeliveryAddressMutation } from "@/hook/Dictionaries/useDeliveryAddressMutation"
import { useDeliveryAddressByIdQuery } from "@/hook/Dictionaries/useDeliveryAddressQuery"
import { usePermission } from "@/hook/usePermission"

import { IDeliveryAddress } from "@/types/IDeliveryAddress"

import { INDEX_LENGTH, PHONE_LENGTH } from "@/utils/constants"
import { filterSelectOption } from "@/utils/filterSelectOption"

const { TextArea } = Input

export interface DeliveryAddressesAddFormProps {
  open(id?: number): void
  close(): void
  onCloseAction?: () => void
}


interface DeliveryAddressesAddFormValues {
  id: number
  region: string
  district: string
  city: string
  street: string
  buildingNumber: string
  postCode: string
  customerId: number
  deliveryCost: number
  approved: boolean
  contactPhone:string
  pump: boolean
  specialMarks:string
}

export const DeliveryAddressesAddForm = forwardRef<DeliveryAddressesAddFormProps, DeliveryAddressesAddFormProps>((props, ref) => {

  const [form] = Form.useForm<DeliveryAddressesAddFormValues>()
  const [id, setId] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isAdminOrManager, setIsAdminOrManager] = useState(false)

  const { hasPermission } = usePermission()

  const { data: customers, isLoading: customerIsLoading } = useCustomerQuery()
  const { data: deliveryAddress, isLoading } = useDeliveryAddressByIdQuery(id)
  const { createMutation, updateMutation } = useDeliveryAddressMutation()

  const title = useMemo(() => {
    if (id) {
      return "Редактировать"
    }

    return "Добавить"
  }, [id])

  useEffect(() => {
    setIsAdminOrManager(hasPermission(Resource.DeliveryAddress, Scope.PartialUpdate))
    if ((deliveryAddress) && isOpen) {
      const response = deliveryAddress.response

      form.setFields([
        {
          name: "id",
          value: response.id
        },
        {
          name: "region",
          value: response.region
        },
        {
          name: "district",
          value: response.district
        },
        {
          name: "city",
          value: response.city
        },
        {
          name: "street",
          value: response.street
        },
        {
          name: "buildingNumber",
          value: response.buildingNumber
        },
        {
          name: "postCode",
          value: response.postCode
        },
        {
          name: "contactPhone",
          value: response.contactPhone
        },
        {
          name: "pump",
          value: response.pump
        },
        {
          name: "specialMarks",
          value: response.specialMarks
        },
        {
          name: "deliveryCost",
          value: response.deliveryCost
        },
        {
          name: "approved",
          value: response.approved
        },
        {
          name: "customerId",
          value: response.customerId
        }
      ])
    }
  }, [id, deliveryAddress, form, isOpen])

  useImperativeHandle(ref, () => ({
    open(id?: number) {
      onOpenHandler(id)
    },
    close() {
      onCloseHandler()
    }
  }))

  const onOpenHandler = (id?: number) => {
    if (id) {
      setId(id)
    }
    form.resetFields()
    setIsOpen(true)
    let customerId = sessionStorage.getItem("currentCustomerId")
    if (!isAdminOrManager && customerId) {
      form.setFieldValue("customerId",customerId)
    }
  
  }

  const onCloseHandler = () => {
    setId(0)
    form.resetFields()
    setIsOpen(false)
    if (props.onCloseAction) {
      props.onCloseAction()
    }
  }

  const onFinish = async (data: DeliveryAddressesAddFormValues) => {
    const deliveryAddress: IDeliveryAddress = {
      approved: data.approved,
      id: data.id,
      customerId: data.customerId,
      region: data.region,
      district: data.district,
      city: data.city,
      street: data.street,
      buildingNumber: data.buildingNumber,
      postCode: data.postCode,
      deliveryCost: data.deliveryCost,
      contactPhone: data.contactPhone,
      pump: data.pump,
      specialMarks: data.specialMarks
      
    }


    try {
      if (data.id == null || data.id == 0) {
        //create
        if (isAdminOrManager)  deliveryAddress.approved = true
        else deliveryAddress.approved = false
        
        await createMutation.mutateAsync(deliveryAddress)
      } 
      else
      {
        //update
        await updateMutation.mutateAsync(deliveryAddress)
      }
      onCloseHandler()
    } catch (error) {
      
    }
  }

  return (
    <>
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        maskClosable={false}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={form.submit}>
            Сохранить
          </Button>
        }
      >
        <Skeleton loading={id === 0 ? false : isLoading} >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item hidden={true}
              name="id"
            >
            </Form.Item>
            <Form.Item
              label="Клиент"
              name="customerId"
              hidden={!isAdminOrManager}
            >
              <Select
                placeholder="Клиент"
                allowClear={false}
                showSearch
                options={customers?.response?.data.map((item) => ({
                  value: item.id,
                  label: item.name
                }))}
                filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
              />
            </Form.Item>
            <Form.Item
              label="Республика, автономная область, край, город федерального назначения, специальная территория"
              name="region"
            >
              <Input placeholder="Республика, автономная область, край, город федерального назначения, специальная территория" />
            </Form.Item>
            <Form.Item
              label="Район"
              name="district"
            >
              <Input placeholder="Район" />
            </Form.Item>
            <Form.Item
              label="Город"
              name="city"
            >
              <Input placeholder="Город" />
            </Form.Item>
            <Form.Item
              label="Улица"
              name="street"
            >
              <Input placeholder="Улица" />
            </Form.Item>
            <Form.Item
              label="Дом, корпус"
              name="buildingNumber"
            >
              <Input placeholder="Дом, корпус" />
            </Form.Item>
            <Form.Item
              label="Почтовый индекс"
              name="postCode"
            >
              <NumericInput maxLength={INDEX_LENGTH} placeholder="Почтовый индекс" />
            </Form.Item>
            <Form.Item 
            label={
              <span>
              Контактный телефон
              <Tooltip title="Укажите контактный телефон на месте выгрузки товара">
                <QuestionCircleOutlined  style={{ marginLeft: "5px", color: "rgba(0,0,0,0.45)" }} />
              </Tooltip>
            </span>
            }
            name="contactPhone"
            rules={[
              { len: PHONE_LENGTH, message: `Номер телефона должен состоять из ${PHONE_LENGTH} цифр` }]}>
              <NumericInput prefix="+7" type="number" placeholder="Номер телефона" maxLength={PHONE_LENGTH}/>
            </Form.Item>
            <Form.Item  valuePropName="checked" name="pump">
              <Checkbox> Насос </Checkbox>
            </Form.Item>
            <Form.Item  name="specialMarks"
            label={
              <span>
              Особые отметки
              <Tooltip title="Укажите возможные особенности места выгрузки, территориальные ограничения, требования к транспорту, диаметр переходника, длина шланга и т.д.">
                <QuestionCircleOutlined  style={{ marginLeft: "5px", color: "rgba(0,0,0,0.45)" }} />
              </Tooltip>
            </span>
            }>
              <TextArea
              placeholder="Особые отметки"
              rows={4}
              maxLength={500}
              showCount
              />
            </Form.Item>
            <Form.Item
              label="Стоимость доставки"
              name="deliveryCost"
              hidden={!isAdminOrManager}
            >
              <NumericInput disabled={ !isAdminOrManager } placeholder="Стоимость доставки" />
            </Form.Item>
            <Form.Item
              label="Подтвержден"
              name="approved"
              valuePropName="checked"
              hidden={!isAdminOrManager}
            >
              <Checkbox disabled={!isAdminOrManager}/>
            </Form.Item>
          </Form>
        </Skeleton>
      </Drawer>
    </>
  )
})