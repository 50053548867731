import { useMutation, useQueryClient } from "@tanstack/react-query"

import { ORDER_QUERY_KEY } from "@/constants/query"
import {
   approveOrder, 
   changeStatusOrder,
   coordinateOrderTime,
   createOrder, 
   recreateOrder,
   deleteOrders,
   payForOrder,
   processStatusOrder,
   updateOrder,
   updateOrderByCustomer,
   updateTransportOrder } from "@/http/order"

import { useMessage } from "@/hook/useMessage"

export const useOrderMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(ORDER_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([ORDER_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const recreateMutation = useMutation({
    mutationFn: recreateOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateByCustomerMutation = useMutation({
    mutationFn: updateOrderByCustomer,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateTransportForOrderMutation = useMutation({
    mutationFn: updateTransportOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteOrders,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const approveMutation = useMutation({
    mutationFn: approveOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const payMutation = useMutation({
    mutationFn: payForOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const coordinateTimeMutation = useMutation({
    mutationFn: coordinateOrderTime,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const changeStatusMutation = useMutation({
    mutationFn: changeStatusOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const processStatusMutation = useMutation({
    mutationFn: processStatusOrder,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    recreateMutation,
    updateMutation,
    updateByCustomerMutation,
    updateTransportForOrderMutation,
    deleteMutation,
    approveMutation,
    payMutation,
    coordinateTimeMutation,
    changeStatusMutation,
    processStatusMutation
  }
}
