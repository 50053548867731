import { IFuelLimit } from "@/types/IFuelLimit"
import { ITableColumn } from "@/types/ui"

import { formatDate } from "@/utils/formatDate"

export const FuelLimitsColumns: ITableColumn<IFuelLimit>[] = [
  {
    title: "ID",
    key: "id",
    dataIndex: ["id"],
    width: 80,
    sorter: true,
    isVisible: false,
    order: 1
  },
  {
    title: "Нефтебаза",
    key: "fuelDepotName",
    dataIndex: ["fuelDepotName"],
    width: 160,
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Тип топлива",
    key: "fuelTypeName",
    dataIndex: ["fuelTypeName"],
    width: 140,
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Начало периода",
    key: "startDate",
    dataIndex: ["startDate"],
    render: (_, { startDate }) => <>{startDate && <span>{formatDate(startDate.toString())}</span>}</>,
    width: 140,
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Конец периода",
    key: "endDate",
    dataIndex: ["endDate"],
    render: (_, { endDate }) => <>{endDate && <span>{formatDate(endDate.toString())}</span>}</>,
    width: 140,
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Дней",
    key: "limitDaysCount",
    dataIndex: ["limitDaysCount"],
    width: 100,
    sorter: false,
    isVisible: true,
    order: 3
  },
  {
    title: "Лимит",
    key: "limit",
    dataIndex: ["limit"],
    width: 150,
    sorter: true,
    isVisible: true,
    order: 4
  }
]
