import React, { FC } from "react"

import { Button, Result } from "antd"

import classes from "./PageError.module.scss"

import { paths } from "@/router/paths"

export const PageError: FC = () => (
  <div className={classes["page-error"]}>
    <Result
      status="500"
      title="500"
      subTitle="Извините, что-то пошло не так"
      extra={<Button href={paths.main} type="primary">На главную</Button>}
    />
  </div>
)
