import { useQuery } from "@tanstack/react-query"

import { CUSTOMER_CATEGORY_CARD_QUERY_KEY, CUSTOMER_CATEGORY_QUERY_KEY, CUSTOMER_CATEGORY_SUM_QUERY_KEY } from "@/constants/query"
import {
  getCustomerCategory, getCustomerCategoryById, getCustomerCategories,
  getCustomerCategorySelectList, getCustomerCategoryByIdForCard,
  getCustomerCategoryPercentSum } from "@/http/customerCategory"

import { IRequestParams } from "@/types/api"

export const useCustomerCategoryQuery = () => {
  return useQuery({
      queryFn: () => getCustomerCategories(),
      queryKey: [CUSTOMER_CATEGORY_QUERY_KEY],
      retry: false
    })
}

export const useCustomerCategoryListQuery = (params?: IRequestParams) => {
  return useQuery({
      queryFn: () => getCustomerCategory(params || {}),
      queryKey: [CUSTOMER_CATEGORY_QUERY_KEY, params],
      retry: false
    })
}

export const useCustomerCategoryByIdQuery = (id: number) => {
  return useQuery({
      queryFn: () => getCustomerCategoryById(id),
      queryKey: [CUSTOMER_CATEGORY_QUERY_KEY, id],
      enabled: id !== 0
    })
}

export const useCustomerCategoryByIdForCardQuery = (id: number) => {
  return useQuery({
      queryFn: () => getCustomerCategoryByIdForCard(id),
      queryKey: [CUSTOMER_CATEGORY_CARD_QUERY_KEY, id],
      enabled: id !== 0
    })
}

export const useCustomerCategoryPercentSumQuery = () => {
  return useQuery({
      queryFn: () => getCustomerCategoryPercentSum(),
      queryKey: [CUSTOMER_CATEGORY_SUM_QUERY_KEY]
    })
}

export const useCustomerCategorySelectListQuery = () => {
  return useQuery({
      queryFn: () => getCustomerCategorySelectList(),
      queryKey: [CUSTOMER_CATEGORY_QUERY_KEY]
    })
}
