import { useQuery } from "@tanstack/react-query"

import { ORDER_LOGS_QUERY_KEY, ORDER_QUERY_KEY } from "@/constants/query"
import { getOrder, getOrderById, getOrdersByCustomer, GetOrderLogsById } from "@/http/order"

import { IRequestParams } from "@/types/api"

export const useOrderQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getOrder(params || {}),
        queryKey: [ORDER_QUERY_KEY, params],
        retry: false
      })
}

export const useOrderByCustomerQuery = (id: number, params?: IRequestParams) => {
  return useQuery({
      queryFn: () => getOrdersByCustomer(params || {}, id),
      queryKey: [ORDER_QUERY_KEY, id, params],
      retry: false
    })
}

export const useOrderByIdQuery = (id: number) => {
    return useQuery({
        queryFn: () => getOrderById(id),
        queryKey: [ORDER_QUERY_KEY, id],
        enabled: id !== 0
      })
}

export const useOrderLogsByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => GetOrderLogsById(id),
    queryKey: [ORDER_LOGS_QUERY_KEY, id],
    enabled: id !== 0
  })
}
