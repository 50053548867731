import { client } from "@/http/client"

import { IResponse } from "@/types/api"
import { IDocumentFile } from "@/types/IDriver"
import { ICreateTimeUnloadFuel, ITimeUnloadFuel, IUpdateAvailableStatusTimeUnloadFuel } from "@/types/ITimeUnloadFuel"

export interface ITimeUnloadFuelByDateParam {
    date: Date
}
export interface ICheckOnFreeSlotParam{
    date: Date
    slot: string
}

export const getAvailableTimeUnloadFuel = (params: ITimeUnloadFuelByDateParam) 
: Promise<IResponse<ITimeUnloadFuel[]>> => 
    client.get("/TimeUnloadFuel/available", { params })

export const getAllTimeUnloadFuelByDate = (params: ITimeUnloadFuelByDateParam) 
: Promise<IResponse<ITimeUnloadFuel[]>> => 
    client.get("/TimeUnloadFuel/all-by-date", { params })

export const getFreeSlotForUnload =(params:ICheckOnFreeSlotParam) 
: Promise<IResponse<ITimeUnloadFuel>> => 
    client.get("/TimeUnloadFuel/free", { params })

export const updateAvailableStatusTimeUnloadFuel =(body:IUpdateAvailableStatusTimeUnloadFuel) 
: Promise<IResponse<ITimeUnloadFuel>> => 
    client.put("/TimeUnloadFuel/available-status", body)

export const createTimeUnload =(body:ICreateTimeUnloadFuel) 
: Promise<IResponse<ITimeUnloadFuel>> => 
    client.post("/TimeUnloadFuel", body)

export const uploadExcel = (files: IDocumentFile[]) 
: Promise<IResponse<boolean>> => 
    client.post("/TimeUnloadFuel/excel", { files: files })

export const deleteTimeUnloadFuel = (slotId: number): Promise<IResponse<number>> =>
    client.delete("/TimeUnloadFuel",{ data: { slotId:slotId } }) 
