import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"


import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { Button, Form, FormInstance, Input, InputNumber, InputRef } from "antd"

import { Table } from "@/components/_Shared/Table"

import { useCustomerCategoryMutation } from "@/hook/Dictionaries/Customers/useCustomerCategoryMutation"

import { ICategoryFuelSetting, ICategoryFuelSettingRequest } from "@/types/ICustomerCategory"
import { ITableColumn } from "@/types/ui"

export interface CategoryFuelSettingsTableProps {
  categoryId: number
  settings: ICategoryFuelSetting[]
  refetchCallback: Function
  additionalColumns?: any[]
}

interface CategorySettingAddFormValues {
  fuelCategorySettingId: number
  fuelId: number
  depotId: number
  addonPrice: number
}

//#region Editable Tabel Row and Cell (todo: abstract)
interface EditableRowProps {
  index: number
}
const EditableContext = createContext<FormInstance<any> | null>(null)

  const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
    const [formItem] = Form.useForm<CategorySettingAddFormValues>()

    return (
      <Form form={formItem} component={false}>
        <EditableContext.Provider value={formItem}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }
  
  interface EditableCellProps<T> {
    title: ReactNode
    editable: boolean
    children: ReactNode
    dataIndex: keyof T
    record: T
    handleSave: (record: T) => void,
    defaultValue?: string | number,
    inputType?: "string" | "number",
    outerChangesCount?: number
  }

  // const lambda function
// const Abc: <T extends Object>(p: EditableCellProps<T>) => FC<EditableCellProps<T>> = (props) => {
//   return <div />
// };

//export const EditableCell: FC<EditableCellProps<ICategoryFuelSetting>> = ({

//type IEditableCell<T = Object> = React.FC<EditableCellProps<T>>

const EditableCell: FC<EditableCellProps<ICategoryFuelSetting>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    defaultValue,
    inputType,
    outerChangesCount,
    ...restProps
  }) => {
    const inputRef = useRef<InputRef>(null)
    const inputNumberRef = useRef<HTMLInputElement>(null)
    const form = useContext(EditableContext)!

    const [isformTouched, setIsFormTouched] = useState<boolean>(false)

    const getIsShowFormValidations = () : boolean => {
      return (outerChangesCount ? outerChangesCount > 0 : false) && isformTouched
    }

    const [isShowFormValidation, setIsShowFormValidation] = useState<boolean>(getIsShowFormValidations)

    useEffect(() => {
      if(outerChangesCount !== null && outerChangesCount === 0) {
        setIsFormTouched(false)
        //form.resetFields()
      }
      setIsShowFormValidation(getIsShowFormValidations)
      // eslint-disable-next-line 
    }, [outerChangesCount])

    const saveOnChange = async (e: any) => {
      try {
        const values = await form.validateFields()
        setIsFormTouched(true)
        setIsShowFormValidation(getIsShowFormValidations)
        handleSave({ ...record, ...values })
      } catch (errInfo) {
        // eslint-disable-next-line 
        console.log("SaveOnChange failed:", errInfo)
      }
    }

    let childNode = children
    if (editable) {
      childNode = (
        <Form.Item
          initialValue={record[dataIndex] === null ? defaultValue : record[dataIndex]}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} необходимо заполнить`
            }
          ]}
        >
          {(inputType && inputType === "number") &&
            <InputNumber ref={inputNumberRef} onPressEnter={saveOnChange} onChange={saveOnChange} style={{ width: "100%" }}
              status={ isShowFormValidation ? "warning" : "" }
              prefix={ isShowFormValidation ? <ClockCircleOutlined /> : <CheckCircleOutlined style={{ color: "green" }}/> }
              />
          }
          {(!inputType || inputType === "string") &&
            <Input ref={inputRef} onPressEnter={saveOnChange} onChange={saveOnChange}
              status={isShowFormValidation ? "warning" : ""}
              prefix={ isShowFormValidation ? <ClockCircleOutlined /> : <CheckCircleOutlined style={{ color: "green" }}/> }
            />
          }
        </Form.Item>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }
//#endregion

export const CategoryFuelSettingsTable: FC<CategoryFuelSettingsTableProps> = (props) => {
  const { categoryId, settings, refetchCallback, additionalColumns } = props

  const [dataSourceSettings, setDataSourceSettings] = useState<ICategoryFuelSetting[]>(settings)

  const [isProcessing, setIsProcessing] = useState(false)
  const [changedSettingsArray, setChangedSettingsArray] = useState<ICategoryFuelSetting[]>([])
  const [changedArrayLength, setChangedArrayLength] = useState<number>(0)

  const { editFuelSettingsMutation } = useCustomerCategoryMutation()

  const editComponents = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  useEffect(() => {
    setDataSourceSettings(settings)
  }, [settings])

  const handleSaveToStack = (row: ICategoryFuelSetting) => {
    const findChangedItem = changedSettingsArray.filter(s => s.fuelId === row.fuelId && s.depotId === row.depotId)

    if(findChangedItem.length > 0) {
      findChangedItem[0].addonPrice = row.addonPrice
    }
    else {
      changedSettingsArray.push(row)
    }
    setChangedArrayLength(changedSettingsArray.length)
  }

  const onFinishSubmitHandler = () => {
    setChangedArrayLength(0)
    setChangedSettingsArray([])
  }

  const tableColumns : ITableColumn<ICategoryFuelSetting>[] = [
    {
      title: "Топливо",
      dataIndex: "fuelTypeName",
      key: "fuelTypeName",
      isVisible: true,
      width: 250,
      order: 1
    },
    {
      title: "Наценка / скидка",
      key: "addonPrice",
      dataIndex: "addonPrice",
      isVisible: true,
      editable: true,
      width: 200,
      onCell: (record: ICategoryFuelSetting) => ({
        record,
        editable: true,
        dataIndex: "addonPrice",
        title: "'Наценка / скидка'",
        handleSave: handleSaveToStack,
        defaultValue: 0,
        inputType: "number",
        outerChangesCount: changedArrayLength
      }),
      order: 2
    },
    {
      title: "",
      dataIndex: "",
      key: "empty",
      isVisible: true,
      order: 3
    },
    {
      title: "fuelId",
      dataIndex: "fuelId",
      key: "fuelId",
      isVisible: false,
      order: 3
    },
    {
      title: "fuelCategorySettingId",
      dataIndex: "fuelCategorySettingId",
      key: "fuelCategorySettingId",
      isVisible: false,
      order: 4
    }
  ]

  const submitFormStack = async () => {
    try {
      const request: ICategoryFuelSettingRequest = {
        id: categoryId,
        fuelSettings: changedSettingsArray
      }
      setIsProcessing(true)

      await editFuelSettingsMutation.mutateAsync(request)

      onFinishSubmitHandler()
      refetchCallback()
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <>
      {settings.length > 0 &&
        <>
          <Button type="primary" disabled={isProcessing || changedArrayLength === 0} onClick={submitFormStack}>
            Сохранить
          </Button>
          <Table
            components={editComponents}
            columns={[...tableColumns, ...(additionalColumns ?? [])]}
            rowKey="fuelId"
            dataSource={dataSourceSettings}
            isSelectable={false}
          />
        </>
      }
    </>
  )
}
