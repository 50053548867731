import { useMutation, useQueryClient } from "@tanstack/react-query"

import { DELIVERY_ADDRESS_QUERY_KEY } from "@/constants/query"
import { createDeliveryAddress, deleteDeliveryAddress, updateDeliveryAddress, updateDeliveryAddressCost } from "@/http/deliveryAddress"

import { useMessage } from "../useMessage"

export const useDeliveryAddressMutation = () => {
    const client = useQueryClient()
    const { Success, Error, Process } = useMessage(DELIVERY_ADDRESS_QUERY_KEY)

    const onSuccess = () => {
        client.invalidateQueries([DELIVERY_ADDRESS_QUERY_KEY]).then()
        Success()
      }
    
      const onMutate = () => {
        Process()
      }
    
      const onError = (e: any) => {
        Error(e)
      }
    
      const createMutation = useMutation({
        mutationFn: createDeliveryAddress,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
      })
    
      const updateMutation = useMutation({
        mutationFn: updateDeliveryAddress,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
      })
    
      const deleteMutation = useMutation({
        mutationFn: deleteDeliveryAddress,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
      })

      const updateDeliveryAddressCostMutation = useMutation({
        mutationFn: updateDeliveryAddressCost,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
      })
    
      return {
        createMutation,
        updateMutation,
        deleteMutation,
        updateDeliveryAddressCostMutation
      }
}