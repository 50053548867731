import React, { createContext, useContext, useState, useEffect, FC } from "react"

import { useNotReadCountNotification } from "@/hook/Notifications/useNotificaitonQuery"

interface NotificationContextProps {
  countNotReadNotifications: number;
  refetchNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const useNotificationContext = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error("Контекст уведомлений не доступен")
  }
  return context
}

export const NotificationProvider: FC<any> = ({ children }) => {
  const [countNotReadNotifications, setCountNotReadNotifications] = useState<number>(0)
  
  const { data, refetch } = useNotReadCountNotification()

  useEffect(() => {
    setCountNotReadNotifications(data?.response ?? 0)
  }, [data])

  const refetchNotifications = () => {
    refetch() 
  }

  return (
    <NotificationContext.Provider value={{ countNotReadNotifications, refetchNotifications }}>
      {children}
    </NotificationContext.Provider>
  )
}
