import { ICustomer } from "@/types/ICustomer"
import { IOrder } from "@/types/IOrder"

export interface IFinance {
  id: number
  date: string
  clientName: string
  sum: number
  operationType: FinanceOperationType
  operationTypeText: string
  paymentType: number
  paymentTypeText: string
  customer: ICustomer
  order?: IOrder
}

export interface IFinanceForGenerate {
  id: number,
  orderId: number,
  orderNumber: string,
  orderSum: number
  customerId: number
}

export interface IFinanceCreate {
  date: string
  sum: number
  paymentType: number
  customerId: number
}

export enum PaymentType {
  Cash,
  Cashless
}

export const paymentTypes = [
  {
    name: "Наличная",
    type: PaymentType.Cash
  },
  {
    name: "Безналичная",
    type: PaymentType.Cashless
  }
]

export enum FinanceOperationType {
  InProgress,
  Expenses ,
  Income,
  Invoice,
  Cancelled
}
