import { useMutation, useQueryClient } from "@tanstack/react-query"

import { DRIVER_QUERY_KEY } from "@/constants/query"
import { createDriver, deleteDrivers, updateDriver, changeVerificationDriver } from "@/http/driver"

import { useMessage } from "@/hook/useMessage"

export const useDriversMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(DRIVER_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([DRIVER_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createDriver,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateDriver,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteDrivers,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const changeVerificationMutation = useMutation({
    mutationFn: changeVerificationDriver,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    changeVerificationMutation
  }
}
