import { useQuery } from "@tanstack/react-query"

import { Resource, Scope } from "@/constants/permission"
import { ORDER_DISPATCHER_QUERY_KEY, ORDER_DISPATCHER_TIME_COUNT_QUERY_KEY, ORDER_DISPATCHER_TIME_QUERY_KEY } from "@/constants/query"
import { getDispatcherOrder, getDispatcherOrderById, getDispatcherTimeCoordinate, getDispatcherTimeCoordinateCount } from "@/http/dispatcherOrders"

import { usePermission } from "@/hook/usePermission"

import { IRequestParams } from "@/types/api"

export const useDispatcherOrderQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getDispatcherOrder(params || {}),
    queryKey: [ORDER_DISPATCHER_QUERY_KEY, params],
    retry: false
  })
}

export const useDispatcherTimeCoordinateQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getDispatcherTimeCoordinate(params || {}),
    queryKey: [ORDER_DISPATCHER_TIME_QUERY_KEY, params],
    retry: false,
    refetchInterval: 60000
  })
}

export const useDispatcherTimeCoordinateCountQuery = () => {
  const { hasPermission } = usePermission()

  return useQuery({
    queryFn: () => getDispatcherTimeCoordinateCount(),
    queryKey: [ORDER_DISPATCHER_TIME_COUNT_QUERY_KEY],
    retry: false,
    refetchInterval: 60000,
    enabled: hasPermission(Resource.DispatcherTimeCoordinate, Scope.Read)
  })
}

export const useDispatcherOrderByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getDispatcherOrderById(id),
    queryKey: [ORDER_DISPATCHER_QUERY_KEY, id],
    enabled: id !== 0
  })
}