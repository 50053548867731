import { useContext, useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/_Shared/FilterWrapper"
import { CustomerContext } from "@/context/CustomerContext"
import { getCustomerClientsSelectList } from "@/http/customerClient"

import { useTransportModelsQuery } from "@/hook/Dictionaries/useTransportModelQuery"
import { useTransportTypesQuery } from "@/hook/Dictionaries/useTransportTypeQuery"
import { useFilterStorage } from "@/hook/useFilterStorage"

import { TRANSPORT_KEY, TransportClassForSelect } from "@/types/ITransport"
import { FilterField, FilterFieldOperator, FilterFieldType } from "@/types/ui"

export const useTransportsFilters = () => {
  const customerContext = useContext(CustomerContext)

  const [filters, setFilters] = useState<FilterField[]>([])
  const { data: transportTypes } = useTransportTypesQuery()
  const { data: transportModels } = useTransportModelsQuery()

  const { getFilter } = useFilterStorage({
    key: TRANSPORT_KEY
  })

  const oldFilters: FieldData[] = getFilter()

  useEffect(() => {
    const newFilters: FilterField[] = [
      {
        text: "Номер",
        name: "Number",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
      },
      {
        text: "Вид транспорта",
        name: "Class",
        type: FilterFieldType.Select,
        values: TransportClassForSelect.map((type) => ({
          id: type.id,
          name: type.value
        }))
      },
      {
        text: "Тип транспорта",
        name: "TransportTypeId",
        type: FilterFieldType.Select,
        values: transportTypes?.response.data
      },
      {
        text: "Модель транспорта",
        name: "TransportModelId",
        type: FilterFieldType.Select,
        values: transportModels?.response.data
      }
    ]

    if (!customerContext.isCustomer()) {
      getCustomerClientsSelectList().then((res) => {
        newFilters.unshift({
          text: "Клиент",
          name: "ClientId",
          type: FilterFieldType.Select,
          values: res.response.map((sli) => ({
            id: sli.value,
            name: sli.label
          }))
        })

        setFilters(newFilters)
      })
    } else {
      setFilters(newFilters)
    }
  }, [transportTypes, transportModels])

  return {
    filters: filters.map((f) => {
      const currentFilter = oldFilters.find(
        (i) => (i.name as string[])[0] === f.name
      )

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      return {
        ...f,
        value:
          f.type === FilterFieldType.Date
            ? dayjs(new Date(currentFilter.value as string))
            : currentFilter.value
      }
    })
  }
}
