import { Period } from "@/types/IOrderReport"


export interface IAnalyticsByCustomerParams {
  optionsList: IAnalyticsByCustomerParamsOptions[]
}

export interface IAnalyticsParams {
  optionsList: IAnalyticsParamsOptions[]
}

export interface IAnalyticsByCustomerParamsOptions {
  activePeriod?: Period
  startDate?: string
  endDate?: string
  fuelTypeId?: number
  depotId?: number
}

export interface IAnalyticsParamsOptions {
  activePeriod?: Period
  startDate?: string
  endDate?: string
  fuelTypeId?: number
  depotId?: number
  customerClientId?: number
  customerCategoryId?: number
}

export interface IOrderAnalytics {
  period: string
  orderCount: number
  orderAmount: number
  orderSum: number
}

export interface BaseDiagramAnalytics {
  rowKey: string
  period: string
}

export interface OrderDiagramAnalytics extends BaseDiagramAnalytics {
  orderCount: number
  orderAmount: number
  orderSum: number
  orderSumMillions: number
}

export interface IPriceAnalytics extends BaseDiagramAnalytics {
  fuels: PriceAnalyticsItem[]
}

interface PriceAnalyticsItem {
  price: number
  fuelTypeName: string
}

export interface IRefusalAnalytics extends BaseDiagramAnalytics {
  refusalCount: number
  refusalAmount: number
  orderCount: number
  orderAmount: number
}

export interface IDeliveriesPickupsAnalytics {
  period: string
  deliveryCount: number
  deliveryAmount: number
  deliverySum: number
  pickupCount: number
  pickupAmount: number
  pickupSum: number
}

export interface IDeliveriesPickupsDiagramAnalytics extends BaseDiagramAnalytics, IDeliveriesPickupsAnalytics {
  deliverySumMillions: number
  pickupSumMillions: number
}

export interface IAnalyticsOptions {
  depotId: number | undefined
  fuelTypeId: number | undefined
  startDate: Date | undefined
  endDate: Date | undefined
  period: AnalyticsPeriod | undefined
  customerId?: number
}

export interface IAnalyticsRefusal {
  period: string
  refusalCount: number
  refusalAmount: number
  orderCount: number
  orderAmountTon: number
}

export interface ComparisonPeriod {
  startDate?: Date
  endDate?: Date
  periodText?: string
}

export enum AnalyticsPeriod {
  Daily,
  Weekly,
  Monthly,
  Quarterly,
  Yearly
}

export interface IAnalyticsPageDisplayUnit {
  value: string
  label: string
  bigLabel: string
}

export enum BaseDisplayUnit {
  OrderAmount,
  OrderSum,
  OrderSumMillions
}

export const baseDisplayUnitsMap = {
  [BaseDisplayUnit.OrderAmount]: {
    value: "orderAmount",
    label: "Тонны",
    bigLabel: "Объем заказов (тонн)"
  },
  [BaseDisplayUnit.OrderSum]: {
    value: "orderSum",
    label: "Рубли",
    bigLabel: "Сумма заказов"
  },
  [BaseDisplayUnit.OrderSumMillions]: {
    value: "orderSumMillions",
    label: "Рубли (млн)",
    bigLabel: "Сумма заказов (млн)"
  }
}

export const diagramColors = [
  "#8884d8",
  "#82ca9d",
  "#F39F18",
  "#008F39",
  "#7FB5B5",
  "#781F19",
  "#1E1E1E",
  "#49678D",
  "#D36E70",
  "#89AC76",
  "#1E2460",
  "#D0D0D0"
]