import { FC } from "react"

import { Card, Space } from "antd"
import { NavLink, useNavigate } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"

import { useCustomerByClientQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"

import { ICustomerByClient } from "@/types/ICustomer"
import { ITableColumn } from "@/types/ui"

import { paths } from "@/router/paths"

export interface CustomersByClientTableProps {
  clientId: number
  additionalColumns?: any[]
}

export const CustomersByClientTable: FC<CustomersByClientTableProps> = (props) => {
  const navigate = useNavigate()
  const { clientId, additionalColumns } = props

  const { data: customers } = useCustomerByClientQuery(Number(clientId))

  const tableColumns : ITableColumn<ICustomerByClient>[] = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      //render: (_: any, customer: ICustomerByClient) => <NavLink to={paths.customerById(customer.id)}>{customer.name}</NavLink>
      isVisible: true,
      order: 1
    },
    {
      title: "Телефон",
      key: "phone",
      dataIndex: ["phone"],
      render: (_: any, customer: ICustomerByClient) => customer.phone ? `+7 ${customer.phone}` : "-",
      isVisible: true,
      order: 2
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      isVisible: true,
      order: 3
    },
    {
      title: "ОГРН",
      dataIndex: "ogrn",
      key: "ogrn",
      isVisible: true,
      order: 4
    },
    {
      title: "Пользователи",
      dataIndex: ["customerUsers"],
      key: "customerUsers",
      render: (_: any, customer: ICustomerByClient) => (
        <>
          <Space direction="vertical" size="small" >
            {
              customer.customerUsers &&
              customer.customerUsers.map((users) => {
                return (
                  <NavLink key={`customerUserByClient${users.userId}`} to={paths.userById(users.userId)}>{users.userName}</NavLink>
                )
              })
            }
          </Space>
        </>
      ),
      isVisible: true,
      order: 5
    }
  ]

  const onRowClickHandler = (data: ICustomerByClient) => {
      navigate(paths.customerById(data.id))
  }

  return (
    <>
      {customers?.response.data &&
        <Card title="Субклиенты">
          <Table
            columns={[...tableColumns, ...(additionalColumns ?? [])]}
            rowKey="id"
            dataSource={customers?.response.data}
            onRowClick={onRowClickHandler}
            isSelectable={false}
          />
        </Card >
      }
    </>
  )
}
